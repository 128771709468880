import React, {useEffect, useState} from "react";
import {Box, IconButton, Popover, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "components/shared/button/button.component";
import "./add-features-filter.scss";
import {DataAssetColumn} from "services/data-assets";
import {containsText} from "utils/contains-text";
import {FilterOperators, MetricType} from "../../../../feature-types";
import SearchComponent from "components/shared/search/search.component";
import BooleanSelect from "../../../../../shared/form/boolean-select.component";
import AutocompleteCreateTimeOption from "../../../../../shared/form/autocomplete-creatable-time.component";
import AutocompleteCreateTimeBetweenOption
    from "../../../../../shared/form/autocomplete-creatable-time-between.component";
import AutocompleteCreateNumberBetweenOption
    from "../../../../../shared/form/autocomplete-creatable-number-between.component";
import AutocompleteCreateNumberOption from "../../../../../shared/form/autocomplete-creatable-number.component";
import AutocompleteCreateNameOption from "../../../../../shared/form/autocomplete-creatable-name.component";


interface FilterItem {
    id: string,
    icon: string,
    title: string,
    type: FilterType,
    operator: string,
    values?: any[],
}

type FilterType = "string" | "number" | "datetime" | "boolean";

interface FilterCondition {
    title: string,
    render?: {
        component: React.ComponentType<any>,
        props: (filterItem: FilterItem, index: number) => any
    }
}

interface AddFeaturesFilterProps {
    assetColumns?: DataAssetColumn[],
    selectedFilters?: MetricType["filters"],
    onFiltersUpdated?: (filters: MetricType["filters"]) => void,
}

const AddFeaturesFilter: React.FC<AddFeaturesFilterProps> = ({
                                                                 assetColumns,
                                                                 selectedFilters,
                                                                 onFiltersUpdated,
                                                             }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [addedFilters, setAddedFilters] = useState<FilterItem[]>([]);
    const [booleanAnchorEl, setBooleanAnchorEl] = useState<HTMLDivElement | null>(null);
    const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
    
    const stringInputProps = (filterItem: FilterItem, index: number) => ({
        onValueSelected: (values: string[]) => handleValueChanged(index, values),
        className: "autocomplete-filter",
        inputClassName: "autocomplete-filter-input",
    })

    const numberInputProps = (filterItem: FilterItem, index: number) => ({
        onValueSelected: (values: number[]) => handleValueChanged(index, values),
    })
    const dateInputProps = numberInputProps

    const booleanInputProps = (filterItem: FilterItem, index: number) => ({
        className: "autocomplete-filter-input",
        setSelectedOptions: (values: string[]) => handleValueChanged(index, values),
        ...(filterItem.values && {selectedOptions: filterItem.values}),
    })
    
    const filterConditionComponents: Record<FilterType, { [key: string]: FilterCondition }> = {
        "string": {
            [FilterOperators.is]: {title: "Is", render: {
                component: AutocompleteCreateNameOption,
                props: stringInputProps  
            }},
            [FilterOperators.is_not]: {title: "Is not", render: {
                component: AutocompleteCreateNameOption,
                props: stringInputProps  
            }},
            [FilterOperators.is_set]: {title: "Is set"},
            [FilterOperators.is_not_set]: {title: "Is not set"}
        },
        "number": {
            [FilterOperators.is]: {title: "Is", render: {
                component: AutocompleteCreateNumberOption,
                props: numberInputProps
            }},
            [FilterOperators.is_not]: {title: "Is not", render: {
                component: AutocompleteCreateNumberOption,
                props: numberInputProps
            }},
            [FilterOperators.gt]: {title: "Greater than", render: {
                component: AutocompleteCreateNumberOption,
                props: numberInputProps
            }},
            [FilterOperators.lt]: {title: "Lower than", render: {
                component: AutocompleteCreateNumberOption,
                props: numberInputProps
            }},
            [FilterOperators.between]: {title: "Between", render: {
                component: AutocompleteCreateNumberBetweenOption,
                props: numberInputProps
            }},
            [FilterOperators.is_set]: {title: "Is set"},
            [FilterOperators.is_not_set]: {title: "Is not set"},
        },
        "boolean": {
            [FilterOperators.is]: {title: "Is", render: {
                component: BooleanSelect,
                props: booleanInputProps
            }},
            [FilterOperators.is_not]: {title: "Is not", render: {
                component: BooleanSelect,
                props: booleanInputProps
            }},
        },
        "datetime": {
            [FilterOperators.lt]: {title: "Before", render: {
                component: AutocompleteCreateTimeOption,
                props: dateInputProps
            }},
            [FilterOperators.gt]: {title: "After", render: {
                component: AutocompleteCreateTimeOption,
                props: dateInputProps
            }},
            [FilterOperators.between]: {title: "Between", render: {
                component: AutocompleteCreateTimeBetweenOption,
                props: dateInputProps
            }},
            [FilterOperators.is_set]: {title: "Is set"},
            [FilterOperators.is_not_set]: {title: "Is not set"},
        }
    };
    const getDefaultFilterOperator = (columnType: string) => columnType === "datetime" ? FilterOperators.lt : FilterOperators.is;

    const filterIcons: Record<FilterType, string> = {
        "string": "match_case",
        "number": "tag",
        "boolean": "hdr_strong",
        "datetime": "event_note"
    };

    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
        setBooleanAnchorEl(null);
    };

    const handleAddFilter = (item: FilterItem) => {
        setAddedFilters((prev) => [...prev, item]);
        handleClose();
    };

    const handleRemoveItem = (removedFilterItemId: string) => {
        setAddedFilters((prev) => prev.filter(filterItem => filterItem.id !== removedFilterItemId));
    };

    const handleOpenBooleanPopover = (event: React.MouseEvent<HTMLDivElement>, type: FilterType) => {
        setBooleanAnchorEl(event.currentTarget);
    };

    const handleOperatorChanged = (newFilterItemIndex: number, operator: string) => {
        setAddedFilters((prev) =>
            prev.map((filterItem, index) => {
                if (index === newFilterItemIndex) {
                    return {
                        ...filterItem,
                        operator: operator || filterItem.operator,
                    };
                }
                return filterItem;
            })
        );
        handleClose();
    };

    const handleValueChanged = (newFilterItemIndex: number, values: any[]) => {
        setAddedFilters((prev) =>
            prev.map((filterItem, index) => {
                if (index === newFilterItemIndex) {
                    return {
                        ...filterItem,
                        values: values,
                    };
                }
                return filterItem;
            })
        );
        handleClose();
    };

    const renderFilterInput = (filterItem: FilterItem, index: number) => {
        const typeOperatorRender = filterConditionComponents[filterItem.type][filterItem.operator].render;
        if (!typeOperatorRender)
            return <></>
        
        return React.createElement(
            typeOperatorRender.component,
            typeOperatorRender.props(filterItem,index)
        )
    }

    // Update list of available filters based on API response and search value
    useEffect(() => {
        const items = Object.values(assetColumns ?? {}).map(
            (column: DataAssetColumn): FilterItem => ({
                id: column.name,
                icon: filterIcons[column.type as FilterType],
                title: column.name,
                type: column.type as FilterType,
                operator: getDefaultFilterOperator(column.type),
            })
        );

        if (searchValue) {
            setFilterItems(items.filter(item => containsText(item["title"], searchValue)));
        } else {
            setFilterItems(items);
        }
    }, [assetColumns, searchValue]);

    // Pass added filters to the parent component to save them
    useEffect(() => {
        const filters = addedFilters.map((filter) => (
            ([String(FilterOperators.is_set), String(FilterOperators.is_not_set)].includes(filter.operator) || Boolean(filter.values?.length)) ?
                {
                    "name": filter.title,
                    "dataType": filter.type,
                    "operator": filter.operator as string,
                    "values": filter.values
                } : null
        )).filter(f => f !== null);

        if (onFiltersUpdated) {
            onFiltersUpdated(filters as MetricType["filters"]);
        }
    }, [addedFilters]);

    return (
        <Box className="flex-box-col-start" sx={{marginBottom: "32px"}}>
            <Typography
                variant="subtitle2"
                sx={{color: theme.palette.customColor.dark, fontWeight: 600, margin: "10px 0 12px 0"}}
            >
                Filters
            </Typography>

            <Box sx={{width: "100%"}}>
                {addedFilters.map((filterItem: FilterItem, filterItemIndex) => (
                    <Box
                        key={filterItem.id}
                        className="measures-selected"
                        sx={{
                            position: "relative",
                            marginBottom: "12px",
                            "&:last-child": {
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Box className="flex-box-align-center" sx={{marginBottom: "6px"}}>
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    fontSize: "20px",
                                    color: theme.palette.customColor.purple,
                                    marginRight: "6px",
                                }}
                            >
                                {filterItem.icon}
                            </span>
                            <Typography
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.dark}}
                            >
                                {filterItem.title}
                            </Typography>
                        </Box>
                        <Box className="flex-box-align-center" sx={{marginLeft: "18px", padding: "6px"}}>
                            {/*Render Button depending on filterItemType */}
                            <ButtonComponent
                                id={filterItem.id}
                                variant="text"
                                label={filterConditionComponents[filterItem.type][filterItem.operator].title}
                                onClick={(e) => handleOpenBooleanPopover(e as unknown as React.MouseEvent<HTMLDivElement>, filterItem.type)}
                                sx={{
                                    minWidth: "auto",
                                    whiteSpace: "nowrap",
                                    marginRight: "10px",
                                    padding: "6px",
                                }}
                            />
                            <Popover
                                id={`${filterItem.id}-operator-select`}
                                className="filter-popover-boolean"
                                open={booleanAnchorEl ? booleanAnchorEl.id === filterItem.id : false}
                                anchorEl={booleanAnchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                            >
                                <Box sx={{marginTop: "8px"}}>
                                    {Object.entries(filterConditionComponents[filterItem.type]).map(([operator, {title}]) => (
                                        <Box
                                            key={`${filterItemIndex}-${filterItem.type}-${operator}`}
                                            className={`flex-box-center-space-between filter-text-boolean ${filterItem.operator === operator ? "active" : ""}`}
                                            sx={{cursor: "pointer", padding: "10px 12px"}}
                                            onClick={() => handleOperatorChanged(filterItemIndex, operator)}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                sx={{color: theme.palette.customColor.darkGrey}}
                                            >
                                                {title}
                                            </Typography>
                                            {filterItem.operator === operator && (
                                                <span className="material-symbols-outlined">check</span>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Popover>
                            {renderFilterInput(filterItem, filterItemIndex)}
                        </Box>
                        <IconButton
                            onClick={() => handleRemoveItem(filterItem.id)}
                            sx={{
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                                color: theme.palette.customColor.darkGrey,
                                "&:hover": {
                                    color: theme.palette.customColor.purple,
                                },
                            }}
                        >
                            <span className="material-symbols-outlined" style={{fontSize: "18px"}}>
                                delete
                            </span>
                        </IconButton>
                    </Box>
                ))}
            </Box>

            <ButtonComponent
                onClick={handleOpenFilter}
                variant="textPurple"
                label="+ Add filter"
                sx={{fontSize: "16px", fontWeight: 600, margin: "12px 0 10px 0", padding: 0}}
            />

            <Popover
                className="filter-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <SearchComponent
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}/>

                <Box sx={{maxHeight: "340px", overflowY: "auto", marginTop: "8px"}}>
                    {filterItems.map((item: FilterItem) => (
                        <Box
                            key={item.id}
                            className="flex-box-align-center"
                            sx={{cursor: "pointer", padding: "10px 16px"}}
                            onClick={() => handleAddFilter(item)}
                        >
                            <span className="material-symbols-outlined">{item.icon}</span>
                            <Typography
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.darkGrey, marginLeft: "8px"}}
                            >
                                {item.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Popover>
        </Box>
    );
};

export default AddFeaturesFilter;
