import React from "react";
import {Box, InputBase} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchProps {
    searchValue: string;
    setSearchValue: (value: string) => void;
}

const SearchComponent: React.FC<SearchProps> = ({
                                                                        searchValue,
                                                                        setSearchValue,
                                                                    }) => {
    return (
        <Box className="flex-box-col-center search-full-width" sx={{maxWidth: "360px", width: "100%"}}>
            <Box sx={{position: "relative", width: "100%"}}>
                <SearchIcon sx={{
                    width: "20px",
                    height: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    left: "16px",
                    zIndex: 999,
                }}
                />
                <InputBase
                    placeholder="Search"
                    inputProps={{"aria-label": "Search data assets"}}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </Box>
        </Box>
    );
};

export default SearchComponent;
