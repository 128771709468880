import {Box, Popover, Typography} from "@mui/material";
import SearchComponent from "components/shared/search/search.component";
import theme from "theme";
import React, {useEffect, useState} from "react";
import {DataAssetColumn, DataAssetFullResponse} from "services/data-assets";
import {containsText} from "../../../../../../../utils/contains-text";
import ButtonComponent from "components/shared/button/button.component";
import {FirstLastFieldType} from "../../../../../feature-types";

interface FieldSelectPopoverProps {
    onClose: () => void;
    anchorEl: Element | null,
    fields: DataAssetFullResponse["columns"],
    selectedFields: string[],
    onFieldsSelected: (fieldNames: FirstLastFieldType["name"][]) => void,
}

interface FieldOption {
    name: string,
    icon: string,
    selected: boolean,
}

const FieldSelectPopover: React.FC<FieldSelectPopoverProps> = ({
                                                                   onClose,
                                                                   fields,
                                                                   selectedFields,
                                                                   onFieldsSelected,
                                                                   anchorEl
                                                               }) => {
    const DEFAULT_ICON = "match_case";
    const initialFieldOptions: FieldOption[] = fields.map(
        f => ({name: f.name, icon: DEFAULT_ICON, selected: selectedFields.includes(f.name)}));

    const [fieldsOptions, setFieldsOptions] = useState<FieldOption[]>(initialFieldOptions);
    const [filteredFieldOptions, setFilteredFieldsOptions] = useState<FieldOption[]>(initialFieldOptions);
    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        const filteredFieldOptions = fieldsOptions.filter((field) =>
            containsText(field.name, searchValue)
        );
        setFilteredFieldsOptions(filteredFieldOptions);
    }, [fieldsOptions, searchValue]);

    const toggleFieldSelected = (fieldName: DataAssetColumn["name"]) => {
        setFieldsOptions((prevFields: any) => prevFields.map(
            (f: any) => f.name === fieldName ? {...f, selected: !f.selected} : f));
    };

    const saveSelectedFields = () => {
        const selectedFieldNames = fieldsOptions
            .filter(f => f.selected)
            .map(f => f.name);
        onFieldsSelected(selectedFieldNames);
        setFieldsOptions(initialFieldOptions);
        setSearchValue("");
        onClose();
    }

    const handleCancel = () => {
        setFieldsOptions(initialFieldOptions);
        setSearchValue("");
        onClose();
    };

    return (
        <Popover open={Boolean(anchorEl)} onClose={handleCancel} anchorEl={anchorEl}
                 anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                 }}
                 transformOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}>
            <Box className="" sx={{width:"380px", padding: "16px", }}>
                <Box className="flex-box-align-center">
                    <SearchComponent
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Box>
                <Box
                    sx={{
                        maxHeight: "390px",
                        overflowY: "auto",
                        marginTop: "16px",
                    }}
                >
                    <Typography variant="h5" sx={{color: theme.palette.customColor.grey, paddingLeft: "12px"}}>
                        All Properties
                    </Typography>
                    {filteredFieldOptions.map((option: FieldOption, index) => (
                        <Box
                            key={option.name}
                            className="flex-box-align-center"
                            sx={{
                                cursor: "pointer",
                                borderRadius: "10px",
                                backgroundColor: option.selected
                                    ? theme.palette.customColor.purpleLight
                                    : "transparent",
                                marginBottom: "3px",
                                padding: "8px 16px",
                                "&:last-child": {
                                    marginBottom: 0,
                                },
                            }}
                            onClick={() => toggleFieldSelected(option.name)}
                        >
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    color: option.selected
                                        ? theme.palette.customColor.purple
                                        : "inherit",
                                }}
                            >
                                {option.icon}
                            </span>
                            <Box sx={{ml: "10px"}}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: option.selected
                                            ? theme.palette.customColor.purple
                                            : theme.palette.customColor.darkGrey,
                                    }}
                                >
                                    {option.name}
                                </Typography>
                            </Box>
                            {option.selected && (
                                <span
                                    className="material-symbols-outlined"
                                    style={{
                                        marginLeft: "auto",
                                        color: theme.palette.customColor.purple,
                                    }}
                                >
                                    check
                                </span>
                            )}
                        </Box>
                    ))}
                </Box>
                <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                    <ButtonComponent
                        variant="text"
                        label="Cancel"
                        onClick={handleCancel}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                    <ButtonComponent
                        variant="contained"
                        label="Add fields"
                        onClick={saveSelectedFields}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                </Box>
            </Box>
        </Popover>
    )
};

export default FieldSelectPopover;