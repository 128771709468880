import React from 'react';
import {Box} from '@mui/material';
import { DataAssetResponse } from 'services/data-assets';
import { RefCallBack } from 'react-hook-form';
import DataAssetSelector from "../../../shared/assets/asset-selector/asset-selector.component";

interface Props {
    onChange: (event: any, newValue: DataAssetResponse | null) => void;
    name: string,
    inputRef: RefCallBack,
    initialValue?: DataAssetResponse | null
}

const AddEditBusinessEntitiesDimTableColumnSelect: React.FC<Props> = ({onChange, initialValue, inputRef, name}) => {

    const onAutocompleteChanged = (event: any, newValue: DataAssetResponse | null) => {
        onChange(event, newValue)
    }

    return (
        <Box className="autocomplete-container" sx={{width: 300, padding: 0}}>
            <DataAssetSelector
                name={name}
                inputRef={inputRef}
                initialValue={initialValue}
                onChange={onAutocompleteChanged} />
        </Box>
    );
};

export default AddEditBusinessEntitiesDimTableColumnSelect;
