import {FormulaType} from "../components/add-features-component/feature-types";

export type FormulaAction =
    | { type: "CREATE"; payload: FormulaType }
    | { type: "DELETE"; payload: { id: string } }
    | { type: "DELETE_ALL" };

export const createFormulaFeature = (formula: FormulaType): FormulaAction => ({
    type: "CREATE",
    payload: {id: `formulaFeature-${Date.now()}`, ...formula}, // using current timestamp as a unique id
});

export const deleteFormulaFeature = (id: string): FormulaAction => ({
    type: "DELETE",
    payload: { id },
});

export const deleteAllFormulaFeatures = (): FormulaAction => ({
    type: "DELETE_ALL"
});
