import React from 'react';
import {Box, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ArrowLeftSymbols, ArrowRightSymbols} from 'assets/icons';
import ButtonComponent from '../../shared/button/button.component';

export interface AddEditBusinessEntitiesViewStepperProps {
    handleBack: () => void
    handleNext: () => void
    onClose: (shouldRefresh?: boolean) => void
    activeStep: number,
    steps: {label: string, subLabel: JSX.Element | string, description: JSX.Element}[]
}

const AddEditBusinessEntitiesViewStepper : React.FC<AddEditBusinessEntitiesViewStepperProps> = ({
    handleBack,
    handleNext,
    onClose,
    activeStep,
    steps
}) => {
    const theme = useTheme();
    return (
        <Box sx={{width: "100%"}}>
             <Box className="flex-box-space-between" sx={{alignItems: 'center', width: '100%', marginBottom: '50px'}}>
                <Box sx={{width: '230px'}}>
                    <ButtonComponent
                        variant="text"
                        label="Back"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        startIcon={<ArrowLeftSymbols/>}
                    />
                </Box>

                <Box className="flex-box-col-center">

                    <Typography variant="h5" sx={{color: theme.palette.customColor.slateGrey}}>
                        Step {activeStep + 1} of {steps.length}
                    </Typography>

                    <Typography
                        variant="h5"
                        sx={{fontSize: '18px', color: theme.palette.customColor.slateGrey, lineHeight: '26px'}}
                    >
                        Add Business Entity
                    </Typography>
                </Box>

                <Box className="flex-box-align-center" sx={{width: '230px'}}>
                    <ButtonComponent
                        variant="outlined"
                        label="Exit"
                        onClick={()=> onClose()}
                        sx={{marginRight: '16px'}}
                    />
                    <>
                        {activeStep !== steps.length - 1 ? (
                            <ButtonComponent
                                variant="contained"
                                endIcon={<ArrowRightSymbols/>}
                                label="Next"
                                onClick={handleNext}
                            />
                        ) : (
                            <ButtonComponent
                                variant="contained"
                                endIcon={<ArrowRightSymbols/>}
                                label="Save"
                                onClick={handleNext}
                                disabled={false}
                            />
                        )}
                    </>
                </Box>
            </Box>

            <Box sx={{width: '100%'}}>
                <Paper
                    className="flex-box-col"
                    square
                    elevation={0}
                    sx={{alignItems: 'center', bgcolor: 'background.default'}}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: '42px',
                            color: theme.palette.customColor.dark,
                            lineHeight: '46px',
                            letterSpacing: '-0.84px',
                        }}
                    >
                        {steps[activeStep]?.label}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontWeight: 400,
                            color: theme.palette.customColor.dimGrey,
                            textAlign: 'center',
                            margin: '24px 0 0 0',
                        }}
                    >
                        {steps[activeStep]?.subLabel}
                    </Typography>
                </Paper>
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesViewStepper;