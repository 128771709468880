import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Box, Checkbox, Chip, FormControlLabel, Popover, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "../button/button.component";
import { OptionType, AutocompleteCreateOptionType } from "./creatable-type";



export default function AutocompleteCreateNameOption({
                                                         options = [],
                                                         label = "",
                                                         onValueSelected,
                                                         className = "",
                                                         inputClassName = "",
                                                     }: AutocompleteCreateOptionType) {
    const [value, setValue] = React.useState<OptionType | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [openIs, setOpenIs] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string>("");
    const [selectedTags, setSelectedTags] = React.useState<OptionType[]>([]);
    const [appliedTags, setAppliedTags] = React.useState<string>("");
    const [isFocused, setIsFocused] = React.useState(false); // New state for focus
    const [includeNull, setIncludeNull] = React.useState(false);

    const handleValueSelected = (_event: React.SyntheticEvent, newValue: any) => {
        if (typeof newValue === "string") {
            setValue({title: newValue});
        } else if (newValue && newValue.inputValue) {
            setValue({title: newValue.inputValue});
        } else {
            setValue(newValue);
        }
        onValueSelected([newValue?.inputValue || ""]);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenIs(true);
        setIsFocused(true);
    };

    const handlePopoverClose = () => {
        if (openIs) {
            setAnchorEl(null);
            setOpenIs(false);
            setIsFocused(false);
        }
    };

    const handleApply = () => {
        handlePopoverClose();
        const formattedTags: string[] = selectedTags.map(tag => tag.title);
        let formattedTagList = "";

        if (includeNull) {
            formattedTags.push("Null");
        }
        if (formattedTags.length > 3) {
            formattedTagList = `${formattedTags.slice(0, 2).join(', ')} or ${formattedTags.length - 2} more`;
        } else if (formattedTags.length > 2) {
            formattedTagList = `${formattedTags.slice(0, 2).join(', ')} or ${formattedTags.slice(2).join(', ')}`;
        } else {
            formattedTagList = formattedTags.join(', ');
        }

        setAppliedTags(formattedTagList);
        onValueSelected(formattedTags);
        setInputValue("");
        setIsFocused(false);
    };

    const handleAddTags = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            const tags = inputValue.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
            const newTags = tags.map(tag => ({title: tag}));
            setSelectedTags(prevTags => [...prevTags, ...newTags]);
            setValue(null);
            setInputValue("");
        }
    };

    const handleDeleteTag = (tagToDelete: OptionType) => {
        setSelectedTags((tags) => tags.filter((tag) => tag.title !== tagToDelete.title));
    };

    return (
        <>
            <Box onClick={handlePopoverOpen} sx={{width: "100%"}}>
                <Autocomplete
                    value={appliedTags}
                    onChange={handleValueSelected}
                    className={className}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={options}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        return option.title;
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.title}
                        </li>
                    )}
                    sx={{width: "100%"}}
                    freeSolo
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            className={inputClassName}
                            placeholder="Values"
                            onKeyDown={handleAddTags}
                            onChange={(e) => setInputValue(e.target.value)}
                            sx={{
                                "& input": {
                                    border: isFocused ? `1px solid ${theme.palette.customColor.purple} !important` : '0',
                                    backgroundColor: isFocused ? 'rgba(103, 73, 244, 0.05)' : 'transparent',
                                },
                            }}
                        />
                    )}
                />
            </Box>

            <Popover
                className="filter-popover-boolean"
                open={openIs}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        width: "374px !important",
                        height: "auto",
                        marginTop: "10px",
                        padding: "12px",
                    },
                }}
            >
                <Box>
                    <Box className="flex-box-center-space-between">
                        <Typography
                            variant="body1"
                            sx={{color: theme.palette.customColor.grey}}
                        >
                            Type in field values
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{ padding: "0" }}
                                    disableRipple
                                    checked={includeNull}
                                    onChange={() => setIncludeNull((prev) => !prev)}
                                />
                        }
                            sx={{marginRight: 0}}
                            label={
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: theme.palette.customColor.black,
                                        "& span": {
                                            color: theme.palette.customColor.lightGrey2,
                                            padding: "2px",
                                            "& svg": {marginRight: "0 !important"}
                                        }
                                    }}
                                >
                                    Include null values
                                </Typography>
                            }
                        />
                    </Box>

                    <Box sx={{margin: "16px 0"}}>
                        <TextField
                            type="text"
                            label={label}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Enter field values..."
                            onKeyDown={handleAddTags}
                            sx={{
                                width: "100%",
                                "& input": {
                                    padding: "12px"
                                }
                            }}
                        />

                        <Box sx={{margin: "16px 0", display: 'flex', flexWrap: 'wrap', gap: '12px'}}>
                            {selectedTags.map((tag, index) => (
                                <Chip
                                    className="filter-tags"
                                    key={index}
                                    label={tag.title}
                                    deleteIcon={<span className="material-symbols-outlined"
                                                      style={{fontSize: "16px", marginLeft: 0}}>close</span>}
                                    onDelete={() => handleDeleteTag(tag)}
                                />
                            ))}

                            {includeNull && (
                                <Chip
                                    className="filter-tags"
                                    label="NULL"
                                    deleteIcon={<span className="material-symbols-outlined"
                                                      style={{fontSize: "16px", marginLeft: 0}}>close</span>}
                                    onDelete={() => setIncludeNull(false)}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box
                        className="flex-box-end"
                        sx={{background: theme.palette.customColor.white}}
                    >
                        <ButtonComponent variant="text" label="Cancel" onClick={handlePopoverClose}/>
                        <ButtonComponent
                            disabled={selectedTags.length === 0 && !includeNull}
                            variant="contained"
                            label="Apply"
                            onClick={handleApply}
                        />
                    </Box>
                </Box>
            </Popover>
        </>
    );
}