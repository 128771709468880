import {MetricType} from "../components/add-features-component/feature-types";

export type MetricAction =
    | { type: "CREATE"; payload: MetricType }
    | { type: "DELETE"; payload: { id: string } }
    | { type: "DELETE_ALL" };

export const createMetricFeature = (metric: MetricType): MetricAction => ({
    type: "CREATE",
    payload: {id: `metricFeature-${Date.now()}`, ...metric}, // using current timestamp as a unique id
});

export const deleteMetricFeature = (id: string): MetricAction => ({
    type: "DELETE",
    payload: { id },
});

export const deleteAllMetricFeatures = (): MetricAction => ({
    type: "DELETE_ALL"
});
