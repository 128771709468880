import {useEffect} from "react";
import * as React from "react";
import {Typography} from "@mui/material";
import theme from "theme";
import {NumberInput} from "@mui/base/Unstable_NumberInput/NumberInput";

type AutocompleteCreateNumberBetweenOptionType = {
    onValueSelected: (values: number[]) => void,
}

export default function AutocompleteCreateNumberBetweenOption({onValueSelected}: AutocompleteCreateNumberBetweenOptionType) {
    const [valueFirst, setValueFirst] = React.useState<number | null>(null);
    const [valueSecond, setValueSecond] = React.useState<number | null>(null);

    useEffect(() => {
        if (valueFirst !== null && valueSecond !== null) {
            onValueSelected([valueFirst, valueSecond]);
        }
    }, [valueFirst, valueSecond]);

    return (
        <>
            <NumberInput
                className="input-number"
                placeholder="Value.."
                value={valueFirst}
                onChange={(event, val) => setValueFirst(val)}
            />

            <Typography
                variant="body1"
                sx={{color: theme.palette.customColor.grey, margin: "0 10px"}}
            >
                and
            </Typography>

            <NumberInput
                className="input-number"
                placeholder="Value.."
                value={valueSecond}
                onChange={(event, val) => setValueSecond(val)}
            />
        </>
    );
}