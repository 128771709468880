import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";

const BusinessEntitiesEditMode = () => {

    return (
        <Box
            className="flex-box-center"
            position="fixed"
            top="0"
            width="100%"
            padding="5px"
            zIndex="1111"
            sx={{backgroundColor: theme.palette.customColor.purple}}
        >
            <Typography variant="body1" noWrap sx={{color: theme.palette.customColor.white, fontWeight: 600}}>
                YOU HAVE EDIT MODE
            </Typography>
        </Box>
    );
};
export default BusinessEntitiesEditMode;
