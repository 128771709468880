import {useEffect, useState} from "react";
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, InputAdornment } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import theme from 'theme';

type AutocompleteCreateTimeOptionType = {
    onValueSelected: (values: string[]) => void,
}

export default function AutocompleteCreateTimeBetweenOption({onValueSelected}: AutocompleteCreateTimeOptionType) {
    const [openFirstPicker, setOpenFirstPicker] = React.useState(false);
    const [focusedFirstPicker, setFocusedFirstPicker] = React.useState(false);
    const [openSecondPicker, setOpenSecondPicker] = React.useState(false);
    const [focusedSecondPicker, setFocusedSecondPicker] = React.useState(false);
    const [firstDate, setFirstDate] = useState<string | null>();
    const [secondDate, setSecondDate] = useState<string | null>();

    // Get the current date and time
    const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm');

    useEffect(() => {
        if (firstDate?.length && secondDate?.length) {
            onValueSelected([firstDate, secondDate]);
        }
    }, [firstDate, secondDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']} sx={{ width: "100%", paddingTop: 0 }}>
                <Box className="flex-box-center-space-between">
                    <DateTimePicker
                        label=""
                        open={openFirstPicker}
                        onOpen={() => {
                            setOpenFirstPicker(true);
                        }}
                        onChange={(newValue) => newValue && setFirstDate(newValue.toISOString())}
                        onClose={() => {
                            setOpenFirstPicker(false);
                            setFocusedFirstPicker(false);
                        }}
                        sx={{
                            width: "100%",
                            "& .MuiInputBase-root input": {
                                border: 0,
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: theme.palette.customColor.purple,
                                padding: "6px",
                            },
                            "& .MuiInputBase-root input::placeholder": {
                                color: theme.palette.customColor.purple,
                            },
                        }}
                        className={focusedFirstPicker ? "data-pick-focused" : ""}
                        slotProps={{
                            textField: {
                                placeholder: currentDateTime,
                                onClick: () => {
                                    setOpenFirstPicker(true);
                                    setFocusedFirstPicker(true);
                                },
                                InputProps: {
                                    endAdornment: <InputAdornment position="end" />,
                                },
                            }
                        }}
                    />
                    <DateTimePicker
                        label=""
                        open={openSecondPicker}
                        onOpen={() => {
                            setOpenSecondPicker(true);
                        }}
                        onChange={(newValue) => newValue && setSecondDate(newValue.toISOString())}
                        onClose={() => {
                            setOpenSecondPicker(false);
                            setFocusedSecondPicker(false);
                        }}
                        sx={{
                            width: "100%",
                            "& .MuiInputBase-root input": {
                                border: 0,
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: theme.palette.customColor.purple,
                                padding: "6px",
                            },
                            "& .MuiInputBase-root input::placeholder": {
                                color: theme.palette.customColor.purple,
                            },
                        }}
                        className={focusedSecondPicker ? "data-pick-focused" : ""}
                        slotProps={{
                            textField: {
                                placeholder: currentDateTime,
                                onClick: () => {
                                    setOpenSecondPicker(true);
                                    setFocusedSecondPicker(true);
                                },
                                InputProps: {
                                    endAdornment: <InputAdornment position="end" />,
                                },
                            }
                        }}
                    />
                </Box>
            </DemoContainer>
        </LocalizationProvider>
    );
}