import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import {Link} from "react-router-dom";

export interface BusinessEntitiesContentInfoProps {
    name: string;
    isEditMode: boolean;
    isActive: boolean;
}

const BusinessEntitiesInfo: React.FC<BusinessEntitiesContentInfoProps> = ({
                                                                              name,
                                                                              isEditMode,
                                                                              isActive,
                                                                          }) => {

    return (
        <Link to={`/entities/${name}`}>
            <Box
                className="flex-box-col-between"
                sx={{
                    borderRadius: "16px",
                    border: `1px solid ${isActive ? theme.palette.customColor.purple : theme.palette.customColor.darkBl}`,
                    backgroundColor: `${isActive ? theme.palette.customColor.purpleLight : "transparent"}`,
                    boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                    padding: "24px",
                    "&:hover" : {
                        borderColor: theme.palette.customColor.purple,
                    }
                }}
            >
                <Box sx={{
                    flex: 1,
                    minHeight: 0,
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: `${isActive ? theme.palette.customColor.purple : theme.palette.customColor.black}`,
                            textTransform: "capitalize",
                        }}>
                        {name.replaceAll("_", " ")}
                    </Typography>
                </Box>
            </Box>
        </Link>
    );
};

export default BusinessEntitiesInfo;