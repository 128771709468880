import React, {FC} from "react";
import {useLocation} from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import studioIcon from "assets/img/logo.png";
import theme from "theme";
import {Link} from "react-router-dom";
import {useGlobalContext} from "context/global-context";
import "./header-menu.component.scss";

const listMenuItems = [
    {
        label: "Entities",
        route: "/entities",
        iconEl: (<Box component="span" sx={{fontSize: "inherit"}} className="material-symbols-outlined icon-menu" aria-label="Hub Icon">package_2</Box>)
    },
    {
        label: "Data assets",
        route: "/data-assets",
        iconEl: (<Box component="span" sx={{fontSize: "inherit"}} className="material-symbols-outlined icon-menu" aria-label="Database Icon">database</Box>)
    },
    // {
    //     label: "Glossary",
    //     route: "/glossary",
    //     iconEl: (<span className="material-symbols-outlined icon-menu" aria-label="Import Contacts Icon">import_contacts</span>)
    // }
]
interface MenuProps {
    drawerOpen: boolean;
    handleDrawerClose: () => void;
    activeIndex: number | null;
    setActiveIndex: (index: number | null) => void;
}

const HeaderMenu: FC<MenuProps> = ({drawerOpen, handleDrawerClose, activeIndex, setActiveIndex}) => {
    const {isEditMode} = useGlobalContext();
    const location = useLocation();

    React.useEffect(() => {
        const currentRouteIndex = listMenuItems.findIndex(menuItem => location.pathname.startsWith(menuItem.route));
        if (currentRouteIndex !== -1) {
            setActiveIndex(currentRouteIndex);
        }
    }, [location.pathname, setActiveIndex]);

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    width: "125px",
                    boxSizing: "border-box",
                    backgroundColor: theme.palette.customColor.lightestGrey,
                    border: 0,
                    margin: "0 auto",
                    top: isEditMode ? "28px" : "0",
                },
            }}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
        >
            <List sx={{padding: "20px 32px 0"}}>
                <Link to="/">
                    <ListItem
                        disablePadding
                        sx={{
                            borderBottom: `1px solid ${theme.palette.customColor.softGrey}`,
                            margin: "0 auto 12px auto",
                            width: 61,
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{margin: "12px 0 24px"}}
                        >
                            <ListItemIcon sx={{minWidth: "auto"}}>
                                <img src={studioIcon} alt="studio" width="61px"/>
                            </ListItemIcon>
                            <Typography
                                variant="body1"
                                sx={{color: theme.palette.customColor.slateGrey, paddingTop: "10px"}}
                                noWrap
                            >
                                STUDIO
                            </Typography>
                        </Box>
                    </ListItem>
                </Link>
                {listMenuItems.map((menuItem, index) => (
                    <Link to={menuItem.route} key={menuItem.label}>
                        <ListItem key={menuItem.label} disablePadding>
                            <ListItemButton
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    margin: "12px 0",
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <ListItemIcon
                                    className={activeIndex === index ? "active" : ""}
                                    sx={{minWidth: "auto", fontSize: "24px"}}
                                >
                                    {menuItem.iconEl}
                                </ListItemIcon>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: activeIndex === index ? theme.palette.customColor.purple : theme.palette.customColor.slateGrey,
                                        paddingTop: "10px",
                                        textAlign: "center",
                                    }}
                                    noWrap
                                >
                                    {menuItem.label}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Drawer>
    );
};

export default HeaderMenu;
