import React, { useState } from "react";
import { Popover, Typography } from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../../shared/button/button.component";
import { operatorOptions } from "constants/related-asset-operators";
import { OperatorOption } from "types";

interface DataAssetRelationOperatorsProps {
    operator: string;
    setOperator: (operator: string) => void;
}

const DataAssetRelationOperatorsComponent: React.FC<DataAssetRelationOperatorsProps> = ({ operator, setOperator }) => {
    
    const getOperatorByValue = (operatorValue : string) => {
        return operatorOptions.find(o=>o.value===operatorValue)
    }
    
    const [anchorElPopover, setAnchorElPopover] = useState<null | HTMLElement>(null);
    const [selectedOperator, setSelectedOperator] = useState<OperatorOption>(getOperatorByValue(operator || "equal") as OperatorOption);
    const isPopoverOpen = Boolean(anchorElPopover);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorElPopover(null);
    };
    
    const handleOperatorSelect = (operator: OperatorOption) => {
        setSelectedOperator(operator);
        setOperator(operator.value);
        handleClosePopover();
    };

    return (
        <>
            <ButtonComponent
                variant="text"
                label={selectedOperator.label}
                onClick={handleOpenPopover}
                disableRipple
                sx={{
                    width: "30px",
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    minWidth: "auto",
                    fontSize: "14px",
                    margin: "0 12px",
                    padding: "13px 9px",
                    border: isPopoverOpen ? `2px solid ${theme.palette.customColor.purple}` : `1px solid ${theme.palette.customColor.lightGrey1}`,
                    "&:hover": {
                        border: `2px solid ${theme.palette.customColor.purple}`,
                    }
                }}
            />

            <Popover
                open={isPopoverOpen}
                anchorEl={anchorElPopover}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                slotProps={{
                    paper: {
                        sx: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "10px",
                            padding: "6px",
                            border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                            borderRadius: "16px",
                            boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                            gap: "3px",
                        },
                    },
                }}
            >
                {operatorOptions.map((operator) => {
                    const isSelected = selectedOperator.label === operator.label
                    return (
                        <Typography
                            key={operator.label}
                            variant="h5"
                            sx={{
                                width: "20px",
                                textAlign: "center",
                                cursor: "pointer",
                                padding: "10px 16px",
                                backgroundColor: isSelected ? theme.palette.customColor.purpleLight : "transparent",
                                color: isSelected ? theme.palette.customColor.purple : theme.palette.customColor.darkGrey,
                                border: "1px solid transparent",
                                borderRadius: "10px",
                                "&:hover": {
                                    border: `1px solid ${theme.palette.customColor.purple}`,
                                },
                            }}
                            onClick={() => handleOperatorSelect(operator)}
                        >
                            {operator.label}
                        </Typography>
                    )
                })}
            </Popover>
        </>
    );
};

export default DataAssetRelationOperatorsComponent;