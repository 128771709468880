import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import theme from 'theme';
import './asset-title.component.scss';

interface AssetTitleProps {
    assetId: string;
    chipOnly?: boolean
}

const AssetTitle: React.FC<AssetTitleProps> = ({ assetId, chipOnly }) => {
    if (!assetId) {
        return (<></>);
    }
    
    const [db, schema, tableName] = assetId.split(".");
    return (
        <Box className="flex-box-center-space-between" width="100%">
            {!chipOnly && (
            <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.black, marginRight: "16px" }}>
                {tableName || assetId}
            </Typography>
            )}

            {db && schema && (<Stack direction="row" spacing={1} alignItems="center">
                <Chip
                    className="asset-title-chip"
                    label={`${db}.${schema}`}
                    color="primary"
                    variant="outlined"
                />
            </Stack>)}
        </Box>
    );
};

export default AssetTitle;