import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import FirstLastSelectedField from "./add-features-first-last-selected-field.component";
import ButtonComponent from "components/shared/button/button.component";
import FieldSelectPopover from "./add-features-first-last-fields.component";
import {DataAssetColumn} from "services/data-assets";
import {SelectedFieldType} from "../../../add-features-first-last-modal-content";
import {FirstLastFieldType} from "../../../../../feature-types";

interface AddFeatureFirstLastFieldsProps {
    selectedAssetColumns: DataAssetColumn[],
    selectedFields: SelectedFieldType,
    onFieldsSelected: (fieldNames: FirstLastFieldType["name"][]) => void,
    onAliasUpdated: (fieldName: FirstLastFieldType["name"], alias: FirstLastFieldType["alias"]) => void,
    onSelectedFieldDeleted: (fieldName: FirstLastFieldType["name"]) => void,
}

const AddFeatureFirstLastFields: React.FC<AddFeatureFirstLastFieldsProps> = (
    {selectedAssetColumns, selectedFields, onFieldsSelected, onAliasUpdated, onSelectedFieldDeleted}
) => {
    const [fieldsPopoverAnchorEl, setFieldsPopoverAnchorEl] = useState<Element | null>(null);
    const openFieldSelectPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
        setFieldsPopoverAnchorEl(e.currentTarget)
    };

    const closeFieldSelectPopover = () => {
        setFieldsPopoverAnchorEl(null)
    };

    return (
        <Box sx={{width: "100%", marginTop: "32px"}}>
            <Typography
                variant="subtitle2"
                sx={{
                    color: theme.palette.customColor.darkGrey,
                    fontWeight: 600,
                    margin: "0 0 12px 0",
                }}
            >
                Fields
            </Typography>

            {Object.keys(selectedFields).length > 0 &&
                <Box sx={{width: "100%"}}>
                    {Object.values(selectedFields).map((field) =>
                        <FirstLastSelectedField
                            field={field}
                            onAliasChanged={onAliasUpdated}
                            onFieldDeleted={onSelectedFieldDeleted}
                        />
                    )}
                </Box>
            }

            <ButtonComponent
                onClick={openFieldSelectPopover}
                variant="textPurple"
                label="+ Add field"
                sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    margin: "12px 0 10px 0",
                    padding: 0,
                }}
            />

            <FieldSelectPopover
                anchorEl={fieldsPopoverAnchorEl}
                onClose={closeFieldSelectPopover}
                fields={selectedAssetColumns}
                selectedFields={Object.keys(selectedFields) || []}
                onFieldsSelected={onFieldsSelected}
            />
        </Box>
    );
};

export default AddFeatureFirstLastFields;