import React from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import reportWebVitals from "reportWebVitals";
import {ThemeProvider} from "@mui/material";
import theme from "theme";
import "fonts.css";
import {GlobalContextProvider} from "./context/global-context";
import AuthProvider from "./context/auth-context";
import App from "./components/app";
import {LoggedInUserProvider} from "./context/logged-in-user-context";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalContextProvider>
                <AuthProvider>
                    <LoggedInUserProvider>
                        <App/>
                    </LoggedInUserProvider>
                </AuthProvider>
            </GlobalContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
