import React from "react";
import {Box, Modal} from "@mui/material";
import AddEditBusinessEntitieViewComponent
    from "components/add-edit-business-entity-component/add-edit-business-entitie-view.component";

interface BusinessEntitiesModalProps {
    entityId?: string | null
    open: boolean;
    onClose: (shouldRefresh: boolean) => void;
}

const BusinessEntityModal: React.FC<BusinessEntitiesModalProps> = ({open, onClose, entityId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="container-modal">
                <AddEditBusinessEntitieViewComponent entityId={entityId} onClose={onClose}/>
            </Box>
        </Modal>
    );
};

export default BusinessEntityModal;