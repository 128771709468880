import {DataAssetTypes} from "constants/common_constants";
import axiosInstance from "../utils/axios";
import {PaginationResponse} from "../types";
import { MeasureType } from "types/features";

export interface PaginationParams {
    pageSize?: number,
    page?: number,
    tableId?: string,
}

export interface Measure {
    description?: string,
    name: string,
    sql: string,
}

export interface DataAssetResponse {
    id: string;
    type: DataAssetTypes;
    name: string;
    schema?: string;
    db?: string;
    description: string;
    entities: string[];
    keys: string[];
    businessKeys: string[];
    measures: Measure[];
    columns: DataAssetColumn[];
}

export interface DataAssetsPaginationResponse extends PaginationResponse {
    assets: DataAssetResponse[]
}

export interface DataAssetColumn {
    name: string;
    description: string;
    type: string;
}

export interface DataAssetFullResponse extends DataAssetResponse {
    columns: DataAssetColumn[]
}

export const getDataAssets = async (params: PaginationParams | null, branch?: string | null): Promise<DataAssetsPaginationResponse> => {
    const queryParams = params ? {
        current_page: params.page,
        items_per_page: params.pageSize,
        table_id: params.tableId,
        branch: branch,
    } : {branch: branch}
    const response = await axiosInstance.get("/assets", {params: queryParams});
    return response.data;
};


export const getDataAssetById = async (
    assetId: string, branch?: string | null, assetType?: string | DataAssetTypes
): Promise<DataAssetFullResponse> => {
    const queryParams = {type: assetType, branch: branch};
    const response = await axiosInstance.get(`/assets/${assetId}`, {params: queryParams});
    return response.data;
};

export const addMeasuresToDataAsset = async (
    assetId: string, measures: MeasureType[], branch?: string | null, assetType: string | DataAssetTypes = DataAssetTypes.asset,
): Promise<DataAssetFullResponse> => {
    const queryParams = {type: assetType, branch: branch};
    const response = await axiosInstance.put(`/assets/${assetId}/measures`, measures, {params: queryParams});
    return response.data;
};
