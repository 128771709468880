import React from "react";
import {Box, TextField} from "@mui/material";

interface AliasInputProps {
    className?: string
    onChange: (value: string) => void
}

const AliasInput:React.FC<AliasInputProps> = ({className = "", onChange}) => {
    return (
        <Box className="flex-box-align-center" sx={{marginLeft: "18px", padding: "6px"}}>
            <span className="material-symbols-outlined" style={{fontSize: "16px"}}>
                alternate_email
            </span>
            <TextField
                hiddenLabel
                className={`alias-input ${className}`}
                placeholder="Enter alias.."
                onChange={(e) => onChange(e.target.value)}
            />
        </Box>
    )
};

export default AliasInput