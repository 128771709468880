import {Box, CircularProgress, Typography} from "@mui/material";
import {ArrowLeftSymbols} from "assets/icons";
import ButtonComponent from "components/shared/button/button.component";
import SqlView from "components/shared/sql-view/sql-view.component";
import {useGlobalContext} from "context/global-context";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {DataAssetFullResponse, DataAssetResponse, getDataAssetById} from "services/data-assets";
import {MetricTemplatePreviewOptions, preview, TemplateType} from "services/templates";
import theme from "theme";
import "./add-features-modal-data.scss";
import {useDebouncedCallback} from "use-debounce";
import {DataAssetName, getDataAssetTitle} from "../../data-asset-name";
import {MetricType} from "../../feature-types";
import AddFeaturesMetricModalContent from "./add-features-metric-modal-content";

interface AddFeaturesModalDataProps {
    dataAssets: DataAssetResponse[];
    selectedAsset: DataAssetFullResponse | null;
    setSelectedAsset: (asset: DataAssetFullResponse | null) => void;
    handleCloseModal: () => void;
    isLoading: boolean;
    errorMessage: string | null;
}

const AddFeaturesMetricModalData: React.FC<AddFeaturesModalDataProps> = ({
                                                                             dataAssets,
                                                                             selectedAsset,
                                                                             setSelectedAsset,
                                                                             handleCloseModal,
                                                                             isLoading,
                                                                             errorMessage,
                                                                         }) => {
    const { entityId } = useParams<{ entityId: string }>();
    const { selectedGitBranch } = useGlobalContext();
    const [sql, setSql] = useState<string>("");

    const fetchDataAsset = async (assetId: string) => {
        try {
            const response = await getDataAssetById(assetId, selectedGitBranch);
            setSelectedAsset(response);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleBack = () => {
        setSelectedAsset(null);
    };

    const updateSql = async (metric: MetricType) => {
        const previewOptions: MetricTemplatePreviewOptions = {
            entity_id: entityId as string,
            asset_id: metric.dataAssetId,
            filters: (metric.filters || []).map(f => ({
                field: f.name,
                operator: f.operator,
                values: f.values,
                data_type: f.dataType,
            })),
            features: (metric.measures || []).map(measure => ({
                sql: measure.definition,
                name: measure.alias || measure.name,
            })),
        };

        const { sql: newSql } = await preview(selectedGitBranch, TemplateType.metric, previewOptions);
        setSql(newSql);
    };

    const debouncedUpdateSql = useDebouncedCallback(updateSql, 300);

    if (errorMessage && !isLoading) {
        return <Typography color="error" variant="body2" sx={{ margin: "auto" }}>{errorMessage}</Typography>;
    }

    if (selectedAsset && !isLoading) {
        return (
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                <Box sx={{ width: "450px", flexShrink: 0 }}>
                    <SqlView sql={sql} />
                </Box>
                <Box sx={{flexGrow: 1, overflow: "auto", width: "100%"}}>
                    <DataAssetName asset={selectedAsset} />

                    <ButtonComponent
                        className="select-button-back"
                        variant="text"
                        label={<ArrowLeftSymbols />}
                        onClick={handleBack}
                    />

                    <AddFeaturesMetricModalContent
                        selectedAsset={selectedAsset}
                        onMetricUpdated={debouncedUpdateSql}
                        onClose={handleCloseModal}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <Box
            className={!dataAssets.length ? "flex-box-col-center" : ""}
            sx={{ width: "100%", height: "100%", overflowY: "scroll" }}
        >
            {isLoading ? (
                <CircularProgress sx={{ fontColor: theme.palette.customColor.grey, margin: "auto" }} />
            ) : (
                dataAssets.length ? (
                    dataAssets.map((asset, index) => (
                        <Box
                            className="modal-data flex-box-align-center"
                            key={index}
                            onClick={() => fetchDataAsset(asset.id)}
                            sx={{ cursor: "pointer" }}
                        >
                            <span className="material-symbols-outlined modal-icon-data">database</span>
                            {getDataAssetTitle(asset)}
                        </Box>
                    ))
                ) : (
                    <Typography variant="subtitle2">
                        No data assets found
                    </Typography>
                )
            )}
        </Box>
    );
};

export default AddFeaturesMetricModalData;