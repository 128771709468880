import axiosInstance from "../utils/axios";

export interface AppSettings {
    frontegg: {
        base_url: string,
        app_id: string,
        client_id: string
    }
}

export const getSettings = async (): Promise<AppSettings> => {
    const response = await axiosInstance.get("/system/settings");
    return response.data;
};