import React, {useEffect, useState} from "react";
import {Box, Chip, Typography} from "@mui/material";
import {DataGrid, GridToolbar, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {DataAssetColumn, Measure} from "services/data-assets";

const gridMeasures: GridColDef<Measure>[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        sortable: true,
        headerClassName: "data-assets-columns--header",
        renderCell: (params: GridRenderCellParams) => (
            <Typography
                className="data-asset-cell-text"
                variant="h5"
            >
                {params.value}
            </Typography>
        ),
    },
    {
        field: "sql",
        headerName: "SQL definition",
        flex: 1,
        sortable: false,
        headerClassName: "data-assets-columns--header",
        renderCell: (params: GridRenderCellParams) => (
            <Chip
                className="asset-title-chip data-asset-cell-text"
                label={params.value}
                color="primary"
                variant="outlined"
            />
        ),
    },
    {
        field: "description",
        headerName: "Column description",
        flex: 1,
        sortable: false,
        headerClassName: "data-assets-columns--header",
        renderCell: (params: GridRenderCellParams) => (
            <Typography
                className="data-asset-cell-text"
                variant="h5"
            >
                {params.value}
            </Typography>
        ),
    },
];

const DataAssetTableMeasures: React.FC<{ measures: Measure[] }> = ({measures}) => {
    const [dataGridMeasures, setDataGridMeasures] = useState<any[]>([]);

    useEffect(() => {
        setDataGridMeasures(
            measures.map((measure, index) => ({
                "id": index,
                ...measure
            }))
        )
    }, [measures]);

    return (
        <Box className="flex-box-col-center" sx={{marginTop: "17px"}}>
            <DataGrid
                hideFooter={true}
                rows={dataGridMeasures}
                columns={gridMeasures}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableAutosize={true}
                className="entity-data-grid"
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                    row: {
                        style: {
                            overflowX: "hidden",
                        }
                    }
                }}
                autoHeight
                initialState={{
                    sorting: {
                        sortModel: [{field: "entity", sort: "asc"}],
                    },
                }}
                sx={{width: "100%"}}
            />
        </Box>
    );
};

export default DataAssetTableMeasures;