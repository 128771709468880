import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { InputAdornment } from '@mui/material';
import theme from 'theme';

type AutocompleteCreateTimeOptionType = {
    onValueSelected: (values: string[]) => void,
}

export default function AutocompleteCreateTimeOption({onValueSelected}: AutocompleteCreateTimeOptionType) {
    const [open, setOpen] = React.useState(false);
    const [focused, setFocused] = React.useState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']} sx={{ width: "100%", paddingTop: 0 }} >
                <DateTimePicker
                    label=""
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onChange={(newValue) => newValue && onValueSelected([newValue.toISOString()])}
                    onClose={() => {
                        setOpen(false);
                        setFocused(false);
                    }}
                    sx={{
                        width: "100%",
                        "& .MuiInputBase-root input": {
                            border: 0,
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "22px",
                            color: theme.palette.customColor.purple,
                            padding: "6px",
                        },
                        "& .MuiInputBase-root input::placeholder": {
                            color: theme.palette.customColor.purple,
                        },
                    }}
                    className={focused ? "data-pick-focused" : ""}
                    slotProps={{
                        textField: {
                            placeholder: "Time...",
                            onClick: () => {
                                setOpen(true);
                                setFocused(true);
                            },
                            InputProps: {
                                endAdornment: <InputAdornment position="end" />,
                            },
                        }
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}