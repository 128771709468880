import React from "react";
import {Box} from "@mui/material";
import "./add-edit-business-entities-related-datasets-select.component.scss";
import DataAssetSelector from "components/shared/assets/asset-selector/asset-selector.component";
import { RefCallBack } from "react-hook-form";
import { DataAssetResponse } from "services/data-assets";

interface Props {
    onChange: (event: any, newValue: DataAssetResponse | null) => void;
    name: string,
    inputRef?: RefCallBack,
    initialValue?: DataAssetResponse | null
}

const AddEditBusinessEntitiesRelatedDatasetsSelect: React.FC<Props> = ({onChange, initialValue, inputRef, name}) => {
    return (
        <Box className="autocomplete-container" sx={{width: "100%", padding: 0}}>
            <DataAssetSelector
                name={name}
                inputRef={inputRef}
                initialValue={initialValue} 
                onChange={onChange} />
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasetsSelect;
