import React, { createContext, useReducer, useContext, ReactNode } from "react";
import {MetricType} from "../components/add-features-component/feature-types";
import {MetricAction} from "../actions/metric-features-reducer";
import {metricReducer} from "../reducers/features-reducer";

interface MetricFeaturesProviderProps {
    children: ReactNode;
}

interface MetricContextType {
    metricFeatures: MetricType[];
    dispatchMetricAction: React.Dispatch<MetricAction>;
}

const MetricContext = createContext<MetricContextType | undefined>(undefined);

const MetricFeaturesProvider: React.FC<MetricFeaturesProviderProps> = ({ children }) => {
    const [metricFeatures, dispatchMetricAction] = useReducer(metricReducer, []);

    return (
        <MetricContext.Provider value={{metricFeatures, dispatchMetricAction}}>
            {children}
        </MetricContext.Provider>
    );
};

const useMetric = (): MetricContextType => {
    const context = useContext(MetricContext);
    if (!context) {
        throw new Error("useMetric must be used within a MetricFeaturesProvider");
    }
    return context;
};

export { MetricFeaturesProvider, useMetric };
