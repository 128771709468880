import React, { createContext, useReducer, useContext, ReactNode } from "react";
import {FirstLastType} from "../components/add-features-component/feature-types";
import {FirstLastAction} from "../actions/first-last-reducer";
import {firstLastReducer} from "../reducers/features-reducer";

interface FirstLastProviderProps {
    children: ReactNode;
}

interface FirstLastContextType {
    firstLastFeatures: FirstLastType[];
    dispatchFirstLastAction: React.Dispatch<FirstLastAction>;
}

const FirstLastContext = createContext<FirstLastContextType | undefined>(undefined);

const FirstLastProvider: React.FC<FirstLastProviderProps> = ({ children }) => {
    const [firstLastFeatures, dispatchFirstLastAction] = useReducer(firstLastReducer, []);

    return (
        <FirstLastContext.Provider value={{firstLastFeatures, dispatchFirstLastAction}}>
            {children}
        </FirstLastContext.Provider>
    );
};

const useFirstLast = (): FirstLastContextType => {
    const context = useContext(FirstLastContext);
    if (!context) {
        throw new Error("useFirstLast must be used within a FirstLastProvider");
    }
    return context;
};

export { FirstLastProvider, useFirstLast };
