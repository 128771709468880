import {UserType} from "types";
import {UserAction} from "../actions/user-reducer";

export const userReducer = (state: UserType | null, action: UserAction): UserType | null => {
    switch (action.type) {
        case "SAVE":
            return action.payload;
        default:
            return state;
    }
};
