import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import ButtonComponent from "../button/button.component";
import theme from "../../../theme";

interface ConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    body: string;
    confirmText: string;
    cancelText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 onConfirm,
                                                                 title,
                                                                 body,
                                                                 confirmText,
                                                                 cancelText,
                                                             }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "573px",
                    width: "100%",
                    bgcolor: "background.paper",
                    p: "40px 32px",
                    borderRadius: "20px",
                    outline: "none",
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 600, color: theme.palette.customColor.dark, mb: 2 }}>
                    {title}
                </Typography>
                <Typography sx={{maxWidth: "484px", fontSize: "18px", fontWeight: 400, lineHeight: "26px", color: theme.palette.customColor.slateGrey, paddingRight: "25px" }}>
                    {body}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: "38px" }}>
                    <ButtonComponent variant={"text"} label={cancelText} onClick={onClose} sx={{marginRight: "16px"}}/>
                    <ButtonComponent variant={"contained"} color={"warning"} label={confirmText} onClick={onConfirm}/>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;