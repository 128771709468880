import * as React from "react";
import {NumberInput} from "@mui/base/Unstable_NumberInput/NumberInput";

type AutocompleteCreateNumberOptionType = {
    onValueSelected: (values: number[]) => void,
}

export default function AutocompleteCreateNumberOption({onValueSelected}: AutocompleteCreateNumberOptionType) {
    return (
        <NumberInput
            className="input-number"
            placeholder="Value.."
            onChange={(event, val) => val && onValueSelected([val])}
        />
    );
}
