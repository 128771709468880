import React, {useState} from 'react';
import {Box, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ArrowLeftSymbols, ArrowRightSymbols} from 'assets/icons';
import ButtonComponent from '../../shared/button/button.component';
import EditModeBusinessEntities from './edit-mode-business-entity/edit-mode-business-entities.component';

export interface AddEditBusinessEntitiesEditStepperProps {
    handleClose: () => void
    onStepClick: (stepIndex: number) => void
    onSave: (()=>void)
    activeStep: number
    entityName: string
}

const AddEditBusinessEntitiesEditStepper: React.FC<AddEditBusinessEntitiesEditStepperProps> = ({
    handleClose,
    onStepClick,
    onSave,
    activeStep,
    entityName
}) => {
    const theme = useTheme();
    return (
        <Box sx={{width:"100%"}}>
            <Box className="flex-box-space-between" sx={{alignItems: 'center', width: '100%', marginBottom: '20px'}}>
                <Box sx={{width: '200px'}}>
                    <ButtonComponent
                        variant="text"
                        label="Exit"
                        onClick={handleClose}
                        startIcon={<ArrowLeftSymbols/>}
                    />
                </Box>

                <Box className="flex-box-col-center">
                    <Typography variant="h2" sx={{color: theme.palette.customColor.dark}}>
                        You are editing: {entityName}
                    </Typography>
                </Box>

                <Box className="flex-box-align-center" sx={{width: '230px'}}>
                    <ButtonComponent
                        variant="contained"
                        endIcon={<ArrowRightSymbols/>}
                        label="Commit changes"
                        onClick={()=>onSave()}
                    />
                </Box>
            </Box>

            <Box sx={{width: '100%'}}>
                <Paper
                    className="flex-box-col"
                    square
                    elevation={0}
                    sx={{alignItems: 'center', bgcolor: 'background.default'}}
                >
                    <EditModeBusinessEntities activeStep={activeStep} onStepClick={onStepClick}/>
                </Paper>
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesEditStepper;
