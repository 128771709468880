export const relationships = [
    {label: "One to one", value: "1:1"},
    {label: "One to many", value: "1:n"},
    {label: "Many to one", value: "n:1"},
    {label: "Many to many", value: "n:n"}
]

export function getRelationshipByValue(value: string){
    return relationships.find(r=>r.value === value)
}

export function getRelationshipLabelByValue(value: string){
    return getRelationshipByValue(value)?.label
}