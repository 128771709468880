import React, { CSSProperties } from "react";
import { Typography } from "@mui/material";
import theme from "theme";

interface FeatureTypeDisplayProps {
    featureType: string;
}

const FeatureTypeDisplay: React.FC<FeatureTypeDisplayProps> = ({ featureType }) => {
    const featureTypes: {[key: string]: {icon: string, sx?: CSSProperties}} = {
        "metric": {icon: "analytics"},
        "first": {icon: "process_chart"},
        "last": {icon: "process_chart"},
        "formula": {icon: "ƒ", sx:{marginTop: "-10px"}},
        "field": {icon: "linked_services"},
    }

    const selectedFeatureType = featureTypes[featureType];

    return (
        <Typography
            variant="subtitle2"
            className="flex-box-align-center"
            sx={{ color: theme.palette.customColor.dark }}
        >
            <span
                className="material-symbols-outlined"
                style={{
                    fontSize: "16px",
                    color: theme.palette.customColor.purple,
                    marginRight: "6px",
                    ...(selectedFeatureType.sx || {})
                }}
            >
                {selectedFeatureType.icon}
            </span>
            {featureType}
        </Typography>
    );
};

export default FeatureTypeDisplay;