import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import {Chip, Tooltip, tooltipClasses} from "@mui/material";
import {MeasureType} from "../../../../types/features";

interface BusinessEntityFeatureDataProps {
    isFormulaFeature?: boolean;
    fieldName: string;
    fieldIcon: string;
    filterCount?: React.ReactNode;
    featureType: string;
    featureField: string;
    featureSortBy: string;
    featureDataAsset: string;
    featureMeasure: string | MeasureType[];
    isFiltersOpen: boolean;
    filtersData: Filter[];
    changeFiltersStyle?: boolean;
}

export interface Filter {
    icon: string;
    field: string;
    operator: string;
    value: string;
}

const BusinessEntityFeatureData: React.FC<BusinessEntityFeatureDataProps> = ({
                                                                                 fieldName,
                                                                                 featureType,
                                                                                 featureField,
                                                                                 featureSortBy,
                                                                                 featureDataAsset,
                                                                                 featureMeasure,
                                                                                 isFiltersOpen,
                                                                                 filtersData,
                                                                                 isFormulaFeature,
                                                                             }) => {


    const dataAssetTooltip = (
        <>
            <Typography variant="h5" sx={{color: theme.palette.customColor.white, opacity: .6,}}>
                db.schema
            </Typography>
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.white}}>
                {featureDataAsset.split('.').slice(0, -1).join('.')}
            </Typography>
        </>
    );
    return (
        <>
            {fieldName === "Type" && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.slateGrey}}>
                    {featureType}
                </Typography>
            )}
            {fieldName === "Field" && featureField && (
                <Chip label={featureField} color="primary" variant="outlined" className="asset-title-chip"/>
            )}
            {fieldName === "Sort by" && featureSortBy && (
                <Chip label={featureSortBy} color="primary" variant="outlined" className="asset-title-chip"/>
            )}
            {fieldName === "Data asset" && (
                <Tooltip
                    title={dataAssetTooltip}
                    placement="top"
                    arrow
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: theme.palette.customColor.dark,
                                borderRadius: "12px",
                                padding: "10px 12px",
                                '& .MuiTooltip-arrow': {
                                    color: theme.palette.customColor.dark,
                                },
                            },
                        },
                    }}
                >
                    <Typography variant="subtitle2" sx={{color: theme.palette.customColor.slateGrey}}>
                        {featureDataAsset.split('.').pop()}
                    </Typography>
                </Tooltip>
            )}
            {fieldName === "Measure" && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.slateGrey}}>
                    {Array.isArray(featureMeasure) ? featureMeasure.join(", ") : featureMeasure}
                </Typography>
            )}
            {fieldName === "Filters" && isFiltersOpen && (
                <Box className="flex-box-col" sx={{gap: "20px", margin: isFormulaFeature ? "16px 0 0 30px" : ""}}>
                    {filtersData.map((filter, index) => (
                        <Box key={index} className="flex-box-start">
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    fontSize: "18px",
                                    color: theme.palette.customColor.purple,
                                    marginRight: "10px",
                                    marginTop: "4px",
                                }}
                            >
                                {filter.icon}
                            </span>
                            <Box>
                                <Chip label={filter.field} color="primary" variant="outlined"
                                      className="asset-title-chip"/>
                                <Box>
                                    <Box className="flex-box-align-center" sx={{marginTop: "10px"}}>
                                        <Typography
                                            variant="body1"
                                            sx={{color: theme.palette.customColor.grey, marginRight: "7px"}}
                                        >
                                            {filter.operator}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{color: theme.palette.customColor.purple, fontWeight: 600}}
                                        >
                                            {filter.value}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};

export default BusinessEntityFeatureData;