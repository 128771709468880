import React, { createContext, useReducer, useContext, ReactNode } from "react";
import {FormulaType} from "../components/add-features-component/feature-types";
import {FormulaAction} from "../actions/formula-features-reducer";
import {formulaReducer} from "../reducers/features-reducer";

interface FormulaFeaturesProviderProps {
    children: ReactNode;
}

interface FormulaContextType {
    formulaFeatures: FormulaType[];
    dispatchFormulaAction: React.Dispatch<FormulaAction>;
}

const FormulaContext = createContext<FormulaContextType | undefined>(undefined);

const FormulaFeaturesProvider: React.FC<FormulaFeaturesProviderProps> = ({ children }) => {
    const [formulaFeatures, dispatchFormulaAction] = useReducer(formulaReducer, []);

    return (
        <FormulaContext.Provider value={{formulaFeatures, dispatchFormulaAction}}>
            {children}
        </FormulaContext.Provider>
    );
};

const useFormula = (): FormulaContextType => {
    const context = useContext(FormulaContext);
    if (!context) {
        throw new Error("useFormula must be used within a FormulaFeaturesProvider");
    }
    return context;
};

export { FormulaFeaturesProvider, useFormula };
