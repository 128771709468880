import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {DataGrid, GridToolbar, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {DataAssetColumn} from "services/data-assets";

const gridColumns: GridColDef[] = [
    {
        field: "name",
        headerName: "Column name",
        flex: 1,
        sortable: true,
        headerClassName: "data-assets-columns--header",
        renderCell: (params: GridRenderCellParams) => (
            <Typography
                className="data-asset-cell-text"
                variant="h5"
            >
                {params.value}
            </Typography>
        ),
    },
    {
        field: "description",
        headerName: "Column description",
        flex: 1,
        sortable: false,
        headerClassName: "data-assets-columns--header",
        renderCell: (params: GridRenderCellParams) => (
            <Typography
                className="data-asset-cell-text"
                variant="h5"
            >
                {params.value}
            </Typography>
        ),
    },
];

const DataAssetTableColumns: React.FC<{ columns: DataAssetColumn[] }> = ({columns}) => {
    const [dataGridColumns, setDataGridColumns] = useState<any[]>([]);

    useEffect(() => {
        setDataGridColumns(
            Object.values(columns).map((column, index) => ({
                "id": index,
                ...column
            }))
        )
    }, [columns]);

    return (
        <Box className="flex-box-col-center" sx={{marginTop: "17px"}}>
            <DataGrid
                hideFooter={true}
                rows={dataGridColumns}
                columns={gridColumns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableAutosize={true}
                className="entity-data-grid"
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                    row: {
                        style: {
                            overflowX: "hidden",
                        }
                    }
                }}
                autoHeight
                initialState={{
                    sorting: {
                        sortModel: [{field: "entity", sort: "asc"}],
                    },
                }}
                sx={{width: "100%"}}
            />
        </Box>
    );
};

export default DataAssetTableColumns;
