import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import {Chip} from "@mui/material";
import "./business-entity-asset-feature-component.scss";
import {FeatureRow} from "../business-entity-tabs/business-entity-tabs.component";
import BusinessEntityAssetFeature from "./business-entity-asset-feature-component";
import {FeatureType} from "../../../types/features";

interface BusinessEntityFormulaProps {
    features: FeatureRow[];
    formulaFeature?: FeatureRow;
}

const BusinessEntityFormula: React.FC<BusinessEntityFormulaProps> = ({
                                                                         features,
                                                                         formulaFeature,
                                                                     }) => {
    const [formulaFeatures, setFormulaFeatures] = useState<FeatureRow[]>([]);

    useEffect(() => {
        const derivedNames = formulaFeature?.featureDerivedName || [];
        if (derivedNames.length > 0) {
            const formulaFeatures = features.filter(feature =>
                derivedNames.includes(feature.featureName)
            );
            setFormulaFeatures(formulaFeatures);
        } else {
            setFormulaFeatures([]);
        }
    }, [features, formulaFeature]);

    const featureTypeIcons: { [key: string]: string } = {
        formula: "ƒ",
        formulaSQL: "tag",
    };

    const featureFieldsFormulaSQL = [
        {name: "Formula SQL:", icon: featureTypeIcons.formulaSQL},
    ];

    return (
        <Box className="flex-box-col-start" sx={{width: "100%", gap: "24px"}}>
            <Box className="flex-box-align-start" sx={{width: "100%"}}>
                <Box className="flex-box-align-center" sx={{width: "138px"}}>
                    <span
                        className="material-symbols-outlined"
                        style={{fontSize: "18px", color: theme.palette.customColor.purple, marginRight: "10px"}}
                    >
                        {formulaFeature?.featureType && featureTypeIcons[formulaFeature.featureType]}
                    </span>
                    <Typography variant="subtitle2" sx={{color: theme.palette.customColor.slateGrey}}>
                        Type:
                    </Typography>
                </Box>
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.slateGrey}}>
                    {FeatureType.formula}
                </Typography>
            </Box>

            {featureFieldsFormulaSQL.map((feature, index) => (
                <Box key={index} className="flex-box-align-start" sx={{width: "100%"}}>
                    <Box className="flex-box-align-center" sx={{width: "138px"}}>
                        <span
                            className="material-symbols-outlined"
                            style={{fontSize: "18px", color: theme.palette.customColor.purple, marginRight: "10px"}}
                        >
                            {feature.icon}
                        </span>
                        <Typography variant="subtitle2" sx={{color: theme.palette.customColor.slateGrey}}>
                            {feature.name}
                        </Typography>
                    </Box>

                    {formulaFeature?.definition && (
                        <Chip
                            label={formulaFeature.definition}
                            color="primary"
                            variant="outlined"
                            className="asset-title-chip"
                        />
                    )}
                </Box>
            ))}

            <Box className="flex-box-col-start" sx={{width: "100%", gap: "24px"}}>
                {formulaFeatures.length > 0 ? (
                    formulaFeatures.map((feature, index) => (
                        <Box key={index} className="flex-box-align-start">
                            <Box className="flex-box-align-center" sx={{width: "138px"}}>
                                <span
                                    className="material-symbols-outlined"
                                    style={{
                                        fontSize: "18px",
                                        color: theme.palette.customColor.purple,
                                        marginRight: "10px"
                                    }}
                                >
                                    tag
                                </span>
                                <Chip
                                    label={feature.featureName}
                                    color="primary"
                                    variant="outlined"
                                    className="asset-title-chip"
                                />
                            </Box>
                            <BusinessEntityAssetFeature assetFeature={feature} formulaFeature={formulaFeature}/>
                        </Box>
                    ))
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default BusinessEntityFormula;