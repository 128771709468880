import React from 'react';
import {Box} from '@mui/material';
import AddEditBusinessEntitiesRelatedTableFirst
, { RelatedEntitiesTableData,
RelatedEntity }    from "./add-edit-business-entities-related-table-first/add-edit-business-entities-related-table-first.component";
import AddEditBusinessEntitiesRelatedTableSecond from './add-edit-business-entities-related-table-second/add-edit-business-entities-related-table-second.component';
import { AddEditBusinessEntityStepProps } from '../add-edit-business-entities-step-props';
import { useForm } from 'react-hook-form';


interface RelatedEntitiesStepFormData {
    entities: RelatedEntity[]
}
interface RelatedAssetsStepProps extends AddEditBusinessEntityStepProps<RelatedEntitiesStepFormData>{
}

const AddEditBusinessEntitiesRelated: React.FC<RelatedAssetsStepProps> = ({
    handleFormValues,
    stepFormValues,
    formRef,
}) => {
    const defaultValue = stepFormValues || {
        entities: [{id: 0, entity: null}]
    }

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
        setValue
    } = useForm({defaultValues: defaultValue});

    const onSubmit = (data: any) => {
        const values = {
            entities: data.entities
        }
        handleFormValues(values);
    };

    const relatedEntitiesChanged = (data: RelatedEntitiesTableData) => {
        setValue("entities", data.entities)
    }
    
    return (
        <Box component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)} className="flex-box-col-center" sx={{maxWidth: '620px', width: '100%'}}>
            <AddEditBusinessEntitiesRelatedTableFirst onChange={relatedEntitiesChanged} initialValues={defaultValue} />
        </Box>
    )
};

export default AddEditBusinessEntitiesRelated;
