import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import "./business-entity-tabs-table.component.scss";
import SearchComponent from "../../../shared/search/search.component";

interface BusinessEntitiesTabsTableProps {
    name: string;
    data: { [key: string]: any }[];
    columns: GridColDef[];
}

interface EntityData {
    id: number;
    entity?: string;
    entitySec?: string;
    relationship?: string;
    featureName?: string;
    type?: string;
    timeline?: string;
    dataAssetName?: string;
    column?: string;
    relation?: string;
}

const BusinessEntityTabsTable: React.FC<BusinessEntitiesTabsTableProps> = ({ data, columns, name }) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [filteredRows, setFilteredRows] = useState<BusinessEntitiesTabsTableProps["data"]>(data);

    const handleSearchTextChange = (value: string) => {
        const lowercasedValue = value.toLowerCase();
        const filtered = data.filter(row =>
            columns.some(column =>
                String(row[column.field as keyof EntityData] ?? "")
                    .toLowerCase()
                    .includes(lowercasedValue)
            )
        );
        setFilteredRows(filtered);
    };

    useEffect(() => {
        handleSearchTextChange(searchValue);
    }, [searchValue, data]);

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value);
    };

    return (
        <Box sx={{ marginBottom: "24px" }}>
            <Box className="flex-box-space-between">
                <SearchComponent
                    searchValue={searchValue}
                    setSearchValue={handleSearchValueChange}
                />
            </Box>
            <DataGrid
                autoHeight
                className={`entity-data-grid ${name}-table`}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowHeight={() => "auto"}
                hideFooter={true}
                rows={filteredRows}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                }}
                sx={{
                    marginTop: "24px",
                }}
            />
        </Box>
    );
};

export default BusinessEntityTabsTable;