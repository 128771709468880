import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Box} from "@mui/material";
import {ArrowDownSymbols} from "assets/icons";
import { useGlobalContext } from "context/global-context";
import { EntityResponse, getEntities } from "services/entities";
import { RefCallBack } from "react-hook-form";

interface Props {
    onChange: (event: any, newValue: EntityResponse | null) => void;
    initialValue?: EntityResponse | null
    name?: string,
    inputRef?: RefCallBack | undefined,
}

const EntitiesSelector: React.FC<Props> = ({onChange, initialValue, inputRef, name}) => {
    const {selectedGitBranch} = useGlobalContext();
    const {isEditMode} = useGlobalContext();
    const [entities, setEntities] = React.useState<readonly EntityResponse[]>([]);

    useEffect(()=>{
        async function fetchEntities(){
            const entities = await getEntities(isEditMode ? selectedGitBranch : null)
            setEntities(entities)
        }
        fetchEntities()
    },[])

    return (
        <Box className="autocomplete-container" sx={{width: "100%", padding: 0}}>
            <Autocomplete
                ref={inputRef}
                popupIcon={<ArrowDownSymbols/>}
                options={entities}
                defaultValue={initialValue}
                ListboxProps={{
                    style: {
                        maxHeight: "344px",
                    }
                }}
                getOptionLabel={(option) => option.name.replaceAll("_", " ")}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        className="autocomplete-select"
                        placeholder="Choose"
                    />
                }
                isOptionEqualToValue={(options, value) => options.id === value.id}
                renderOption={(props, option) => (
                    <li {...props} className="MuiAutocomplete-option MuiAutocomplete-option-custom">
                        {option.name.replaceAll("_", " ")}
                        <span className="material-symbols-outlined check-icon">check</span>
                    </li>
                )}
                onChange={onChange}
            />
        </Box>
    );
};

export default EntitiesSelector;
