import React, { useEffect, useMemo, useState } from "react";
import { debounce, MenuItem, SelectChangeEvent } from "@mui/material";
import { DataAssetResponse, getDataAssets } from "../../../../services/data-assets";
import SelectComponent from "../../select/select.component";
import AssetTitle from "../asset-title/asset-title.component";
import {ChevronDown} from "lucide-react";
import theme from "../../../../theme";
import "./asset-selector.component.scss";

interface SelectOption {
    id: string;
    title: string;
}

interface DataAssetSelectorProps {
    name?: string;
    inputRef?: React.RefCallback<any>;
    initialValue?: DataAssetResponse | null;
    onChange?: (event: any, newValue: DataAssetResponse | null) => void;
}

const DataAssetSelector: React.FC<DataAssetSelectorProps> = ({ initialValue, onChange }) => {
    const [selectedValue, setSelectedValue] = useState<string>(initialValue?.id || "");
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [dataAssetMap, setDataAssetMap] = useState<Map<string, DataAssetResponse>>(new Map());
    const [searchText, setSearchText] = useState<string>("");

    const fetchAssets = useMemo(
        () =>
            debounce(async (filter?: string) => {
                const dataAssetsPage = await getDataAssets({ tableId: filter });

                const options = dataAssetsPage.assets.map((asset: DataAssetResponse) => ({
                    id: asset.id,
                    title: asset.name,
                }));

                const dataAssetMap = new Map(dataAssetsPage.assets.map(asset => [asset.id, asset]));

                setOptions(options);
                setDataAssetMap(dataAssetMap);
            }, 400),
        []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selected = dataAssetMap.get(event.target.value) || null;
        setSelectedValue(event.target.value);
        if (onChange) onChange(event, selected);
    };

    const renderSelectedValue = () => {
        const selected = dataAssetMap.get(selectedValue);
        return selected ? <AssetTitle assetId={selected.id} /> : "Select DIM table";
    };

    useEffect(() => {
        fetchAssets(searchText);
    }, [searchText, fetchAssets]);

    return (
        <SelectComponent
            className="asset-select"
            options={options}
            value={selectedValue}
            onChange={handleChange}
            onSearchChange={handleSearchChange}
            renderOption={(option: SelectOption) => (
                <MenuItem key={option.id} value={option.id}>
                    <AssetTitle assetId={option.id} />
                </MenuItem>
            )}
            renderValue={renderSelectedValue}
            placeholder="Select DIM table"
            showSearch={true}
            IconComponent={(props) => <ChevronDown size={18} color="#1B1A1C" {...props} />}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                PaperProps: {
                    sx: {
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        maxHeight: "449px",
                        width: "380px",
                        margin: "10px 0 0 50px",
                        padding: "6px",
                        ul: {
                            padding: 0,
                        },
                        li: {
                            backgroundColor: "transparent !important",
                            color: theme.palette.customColor.darkGrey,
                            fontSize: "16px",
                            lineHeight: "24px",
                            padding: "10px 16px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                            "&:first-of-type": {
                                padding: "0 0 8px 0",
                            },
                            "&.search-input": {
                                padding: "16px 16px 16px 41px",
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default DataAssetSelector;