import { DataAssetTypes } from "constants/common_constants";
import { FeatureType, MeasureType } from "types/features";

export type FeatureModelType = "metric" | "first/last" | "formula";

export enum FilterOperators {
    is = "is",
    is_not = "is_not",
    is_set = "is_set",
    is_not_set = "is_not_set",
    lt = "lt",
    gt = "gt",
    between = "between"
}

export interface FeatureBaseType {
    featureName: string;
    type: FeatureType;
}

export interface AssetFeatureDataApi extends FeatureBaseType{
    dataAssetId: string;
    dataAssetType: DataAssetTypes;
    feature: MetricType | FirstLastType
}


export interface AssetFeatureData extends AssetFeatureDataApi {
    id: string;
}


export interface FeatureFilterType {
    name: string,
    dataType: string,
    operator: string,
    values?: any
}


export interface DerivedFeatureDataApi extends FeatureBaseType {
    feature: FormulaType
}

export interface DerivedFeatureData extends DerivedFeatureDataApi {
    id: string;
}

export interface MetricType {
    id?: string,
    dataAssetId: string,
    dataAssetType: DataAssetTypes,
    filters?: FeatureFilterType[],
    measures?: MeasureType[]
}

export interface FirstLastFieldType {
    name: string,
    alias?: string,
    selected?: boolean,
}

// stores First/Last feature user adds a new feature
export interface FirstLastType {
    id?: string,
    dataAssetId: string,
    dataAssetType: DataAssetTypes,
    filters?: FeatureFilterType[],
    method: "first" | "last",
    sortBy: string,
    fields: FirstLastFieldType[]
}

// stores First/Last feature draft when user creates a new one
export interface FirstLastDraftType {
    dataAssetId?: string,
    filters?: FeatureFilterType[],
    method?: "first" | "last",
    sortBy?: string,
    fields?: FirstLastFieldType[]
}

export interface FormulaFeature {
    feature: string,
    alias?: string
}

export interface FormulaType {
    id?: string,
    name: string,
    sql: string,
    features: FormulaFeature[]
}

// Formula Feature
export interface FormulaFeatureSelection {
    name: string;
    alias?: string;
    selected?: boolean;
    id: number;
    showIcon?: boolean;
}