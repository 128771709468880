import React from "react";
import {MenuItem, Checkbox, Typography, Select, OutlinedInput} from "@mui/material";
import theme from "theme";

interface BooleanSelectProps {
    className?: string;
    selectedOptions?: string[];
    setSelectedOptions: (selectedOptions: string[]) => void;
}

interface OptionType {
    title: string;
    disabled?: boolean;
}

const BooleanSelect: React.FC<BooleanSelectProps> = ({selectedOptions = [], setSelectedOptions}) => {

    const options: OptionType[] = [
        {title: "True"},
        {title: "False"},
        {title: "Null"},
    ];

    const handleChange = (values: string[]) => {
        setSelectedOptions(values);
    };

    return (
        <Select
            multiple
            value={selectedOptions}
            onChange={(e) => handleChange(e.target.value as string[])}
            input={<OutlinedInput label="Tag"/>}
            displayEmpty
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <Typography variant="h5" sx={{color: theme.palette.customColor.purple}}>Select
                        option</Typography>;
                }
                return selected.join(", ");
            }}
            sx={{
                "& .select-placeholder": {
                    color: theme.palette.customColor.purple,
                },
                "& .MuiSelect-icon": {
                    display: "none",
                },
                "& .MuiSelect-select": {
                    fontSize: "14px",
                    padding: "6px",
                },
                "& .MuiSelect-select[aria-expanded='true']": {
                    backgroundColor: "rgba(103, 73, 244, 0.05)",
                    border: "2px solid transparent",
                },
                "& .MuiSelect-select[aria-expanded='false']": {
                    border: "2px solid transparent",
                },
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        marginTop: "11px",
                        li: {
                            backgroundColor: "transparent !important",
                            padding: "9px 12px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                            span: {
                                color: theme.palette.customColor.lightGrey1,
                                padding: 0,
                            }
                        },
                    },
                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option.title} value={option.title}>
                    <Checkbox
                        checked={selectedOptions.indexOf(option.title) > -1}
                        disableRipple={true}
                    />
                    <Typography
                        variant="h5"
                        sx={{color: theme.palette.customColor.darkGrey}}
                    >
                        {option.title}
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    );
};

export default BooleanSelect;