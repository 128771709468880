import LoadingSpinner from "components/shared/LoadingSpinner";
import React, {createRef, useEffect, useMemo, useState,} from "react";
import {Box} from "@mui/material";
import AddEditBusinessEntitiesDetails, {AddEditBusinessEntitiesDetailsValues}
    from "../add-edit-business-entities-details/add-edit-business-entities-details.component";
import AddEditBusinessEntitiesDimTable
    from "../add-edit-business-entities-dim-table/add-edit-business-entities-dim-table.component";
import AddEditBusinessEntitiesRelatedDatasets
    , {
    RelatedAssetsStepFormData
} from "../add-edit-business-entities-related-datasets/add-edit-business-entities-related-datasets.component";
import AddEditBusinessEntitiesRelated
    from "../add-edit-business-entities-related/add-edit-business-entities-related.component";
import {saveNewEntity, getEntity, EntityDetail, updateEntity, RelationKind, EntityDetailResponse} from "services/entities";
import {DataAssetFullResponse, getDataAssetById} from "services/data-assets";
import {useGlobalContext} from "context/global-context";
import {
    RelatedEntitiesTableData,
    RelatedEntity
} from "../add-edit-business-entities-related/add-edit-business-entities-related-table-first/add-edit-business-entities-related-table-first.component";
import AddEditBusinessEntitiesViewStepper from "./add-edit-business-entities-view-stepper.component";
import AddEditBusinessEntitiesEditStepper from "./add-edit-business-entities-edit-stepper.component";

export interface BusinessEntitiesModalCloseProps {
    onClose: (shouldRefresh: boolean) => void;
    entityId?: string | null;
}

type EntityDataArray = [AddEditBusinessEntitiesDetailsValues | null, {
    keyTable: string | null
} | null, RelatedAssetsStepFormData | null, RelatedEntitiesTableData | null]

const AddEditBusinessEntitiesStepperView: React.FC<BusinessEntitiesModalCloseProps> = ({onClose, entityId}) => {
    const {selectedGitBranch} = useGlobalContext();
    const [goToIndex, setGoToIndex] = useState<number | null>(() => null);
    const [loading, setLoading] = useState<boolean>(true);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [currentEntity, setCurrentEntity] = useState<EntityDetailResponse | null>(null);
    const [stepsValues, setStepsValue] = useState<EntityDataArray>([null, null, null, null]);

    const formRefs = useMemo(() => Array(4).fill(0).map(() => createRef<HTMLFormElement>()), []);

    const getAssetsData = async (assetIds: string[]): Promise<DataAssetFullResponse[]> => {
        return Promise.all(assetIds.map(aId => getDataAssetById(aId)));
    };

    useEffect(() => {

        async function getEntityDetails() {
            const entity = await getEntity((entityId as string), selectedGitBranch);
            const assetsDetails = await getAssetsData(Object.keys(entity.relatedAssets));
            const details: AddEditBusinessEntitiesDetailsValues = {
                aliases: entity.aliases.map(a => {
                    return {value: a};
                }),
                description: entity.description,
                name: entity.name
            };

            const dimTable: { keyTable: string | null } = {
                keyTable: entity.keyTable
            };

            const relatedAssets: RelatedAssetsStepFormData = {
                assets: Object.keys(entity.relatedAssets).map((assetId, index) => {
                    return {
                        id: index,
                        asset: assetsDetails[index],
                        assetFields: assetsDetails[index]?.columns.map(c => c.name),
                        sql: entity.relatedAssets[assetId].sql || "",
                        relationKind: entity.relatedAssets[assetId].type,
                        entityAssetRelations: (entity.relatedAssets[assetId].fields || []).map((field) => ({
                            entityField: field.entity_field,
                            dataAssetField: field.asset_field,
                            operator: field.operator,
                        })),
                    };
                })
            };

            const relatedEntitiesData: RelatedEntitiesTableData = {
                entities: Object.keys(entity.relatedEntities).map((key, index) => {
                    return {
                        id: index,
                        relationship: entity.relatedEntities[key],
                        entity: {
                            id: key,
                            name: key
                        }
                    } as RelatedEntity;
                })
            };

            setCurrentEntity(entity)
            setStepsValue([details, dimTable, relatedAssets, relatedEntitiesData]);
            setLoading(false);
        }

        if (entityId) {
            getEntityDetails();
        } else {
            setLoading(false);
        }
    }, [entityId]);

    // stepsValues are updating only when moving between steps
    useEffect(() => {
        async function move() {
            // If last step and no entity ID (new entity) / have entity ID (edit) and goToIndex is -1 - save
            if ((activeStepIndex === steps.length - 1 && !entityId) || goToIndex === -1) {
                await saveOrUpdate();
                return;
            }

            // -1 indicates saving directly
            if (goToIndex !== null && goToIndex !== -1) {
                setActiveStepIndex(goToIndex);
                setGoToIndex(null);
                return;
            }
        }

        move();
    }, [stepsValues]);

    const handleNext = (stepIndex?: number) => {
        formRefs[activeStepIndex].current?.requestSubmit();
        setGoToIndex((stepIndex || stepIndex === 0) ? stepIndex : activeStepIndex + 1);
    };

    const handleBack = () => {
        setActiveStepIndex((prevActiveStep) =>
            prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
        );
    };

    const handleCloseModal = (shouldRefresh: boolean = false) => {
        setGoToIndex(null);
        setActiveStepIndex(0);
        onClose(shouldRefresh);
    };

    const saveOrUpdate = async () => {
        const [details, dimTable, relatedAssets, relatedEntities] = (stepsValues as EntityDataArray);

        if ([details, dimTable, relatedAssets, relatedEntities].some(value => value === null)) {
            console.log("Missing values to save");
            return;
        }

        let parsedRelatedEntities: EntityDetail["relatedEntities"] = {};

        if (relatedEntities) {
            parsedRelatedEntities = relatedEntities.entities.reduce((prev, cur) =>
                ({...prev, [cur.entity?.name || ""]: cur.relationship}), {}
            );
        }

        let parsedRelatedAssets = {};

        if (relatedAssets) {
            parsedRelatedAssets = relatedAssets.assets.reduce((prev, cur) => ({
                ...prev,
                [cur.asset?.id || ""]: {
                    type: cur.relationKind as RelationKind,
                    sql: cur.sql?.length ? cur.sql : null,
                    fields: cur.entityAssetRelations?.length
                        ? cur.entityAssetRelations.map(r => ({
                            entity_field: r.entityField,
                            asset_field: r.dataAssetField,
                            operator: r.operator,
                        })) : null,
                }
            }), parsedRelatedAssets);
        }

        const entity: EntityDetail = {
            name: details?.name.toLowerCase() || "",
            description: details?.description,
            aliases: (details?.aliases || []).map(a => a.value.toLowerCase()),
            keyTable: dimTable?.keyTable || "",
            relatedAssets: parsedRelatedAssets,
            relatedEntities: parsedRelatedEntities,
            derivedFeatures: currentEntity?.derivedFeatures || [],
            assetFeatures: currentEntity?.assetFeatures || []
        };
        // If is new, save as new
        if (!entityId)
            await saveNewEntity((selectedGitBranch as string), entity);
        // Update existing
        else
            await updateEntity(entityId, (selectedGitBranch as string), entity);

        onClose(true);
    };

    const handleStepFormValues = async (values: any) => {
        const valuesArray = (Array.from(stepsValues) as EntityDataArray);
        valuesArray[activeStepIndex] = values;
        setStepsValue(valuesArray);
    };

    const steps = [
        {
            label: "Entity details",
            subLabel: "",
            description: <AddEditBusinessEntitiesDetails handleFormValues={handleStepFormValues}
                                                         stepFormValues={stepsValues[0]} formRef={formRefs[0]}/>,
        },
        {
            label: "Entity DIM table",
            subLabel: (
                <>
                    The DIM dataset is a dataset that contains all the possible instances of
                    <br/>
                    that entity and for each instance it has only one record.
                </>
            ),
            description: <AddEditBusinessEntitiesDimTable handleFormValues={handleStepFormValues}
                                                          stepFormValues={stepsValues[1]} formRef={formRefs[1]}
                                                          showDimTableInfo/>,
        },
        {
            label: "Related datasets",
            subLabel: (
                <>
                    Choose datasets that relate to "business entity name"
                    <br/>
                    You will be able to edit it later
                </>
            ),
            description: <AddEditBusinessEntitiesRelatedDatasets handleFormValues={handleStepFormValues}
                                                                 dimTable={stepsValues[1]?.keyTable || ""}
                                                                 stepFormValues={stepsValues[2]}
                                                                 formRef={formRefs[2]}
                                                                 entityName={stepsValues[0]?.name || ""}

            />,
        },
        {
            label: "Related entities",
            subLabel: "Map the entity relationships for \"business entity name\"",
            description: <AddEditBusinessEntitiesRelated handleFormValues={handleStepFormValues}
                                                         stepFormValues={stepsValues[3]} formRef={formRefs[3]}/>,
        },
    ];

    return (
        loading ? (<LoadingSpinner open={true}/>) : (

            <Box className="flex-box-col-center"
                 sx={{maxWidth: "1232px", width: "100%", marginTop: "32px", paddingBottom: "100px"}}>

                {entityId ?
                    (<AddEditBusinessEntitiesEditStepper entityName={stepsValues[0]?.name || ""}
                                                         handleClose={() => handleCloseModal()} onStepClick={handleNext}
                                                         onSave={() => handleNext(-1)} activeStep={activeStepIndex}/>) :
                    (<AddEditBusinessEntitiesViewStepper handleBack={handleBack} handleNext={() => handleNext()}
                                                         steps={steps} activeStep={activeStepIndex}
                                                         onClose={handleCloseModal}/>)
                }

                <Box className="flex-box-center" sx={{width: "100%"}}>
                    {steps[activeStepIndex]?.description}
                </Box>
            </Box>
        )
    );
};

export default AddEditBusinessEntitiesStepperView;