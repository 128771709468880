import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {GitBranch} from "lucide-react";
import theme from "theme";
import {getGitBranches} from "services/entities";
import {useGlobalContext} from "context/global-context";
import {Autocomplete, TextField} from "@mui/material";
import CustomSwitch from "components/shared/switch/switch.component";
import "./header-switch.component.scss";

const HeaderSwitch = () => {
    const {
        isEditMode,
        toggleEditMode,
        selectedGitBranch,
        setSelectedGitBranch
    } = useGlobalContext();
    const [branches, setBranches] = useState<string[]>([]);
    const [open, setOpen] = useState(false);

    const handleChange = () => {
        toggleEditMode((isEditMode) => !isEditMode);
    };

    const handleBranchSelect = (event: React.SyntheticEvent<Element, Event>, branch: string | null) => {
        if (branch !== selectedGitBranch) setSelectedGitBranch(branch);
    };

    useEffect(() => {
        const getBranches = async () => {
            try {
                const data = await getGitBranches();
                setBranches(data);
                // initialize selected branch as the first one from the list
                if (!selectedGitBranch && data?.length > 0) setSelectedGitBranch(data[0]);
            } catch (error: any) {
                console.error(error);
            }
        };
        if (branches.length === 0) getBranches();
    }, [branches]);


    return (
        <Box className="flex-box-align-center" sx={{marginLeft: "40px"}}>
            <CustomSwitch checked={isEditMode} onChange={handleChange}/>
            <Typography variant="h5" noWrap sx={{color: theme.palette.customColor.grey, ml: "16px"}}>
                Edit mode
            </Typography>
            {isEditMode && (
                <Autocomplete
                    options={branches}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={selectedGitBranch}
                    onChange={handleBranchSelect}
                    className={`branch-select-component ${open ? "branch-active" : ""}`}
                    sx={{
                        border: "1px solid transparent",
                        borderRadius: "16px",
                        padding: "10px 12px",
                        "&:hover": {
                            borderColor: theme.palette.customColor.lightGrey1,
                        },
                    }}
                    renderOption={(props, option) => {
                        const {key, ...optionProps} = props;
                        return (
                            <Box
                                key={key}
                                component="li"
                                {...optionProps}
                                sx={{
                                    position: "relative",
                                    borderRadius: "10px !important",
                                    marginBottom: "3px",
                                    "&[aria-selected='false']:hover": {
                                        border: `1px solid ${theme.palette.customColor.purple}`,
                                        backgroundColor: theme.palette.customColor.white,
                                        padding: "9px 11px",
                                        "& h6": {
                                            color: theme.palette.customColor.purple,
                                        },
                                    },
                                    "&[aria-selected='true']": {
                                        "& .branch-check-icon": {
                                            opacity: "1",
                                        },
                                        "& h6": {
                                            color: theme.palette.customColor.purple,
                                        },
                                    },
                                    "&:nth-last-child(1)" : {
                                        marginBottom: 0,
                                    }
                                }}
                            >
                                <Typography variant="subtitle2">{option}</Typography>
                                <span className="material-symbols-outlined branch-check-icon">check</span>
                            </Box>
                        )
                    }}
                    renderInput={(params) => (
                        <Box sx={{display: "flex"}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    backgroundColor: theme.palette.customColor.lightestGrey2,
                                    borderRadius: "8px",
                                    marginRight: "10px",
                                    padding: "8px",
                                    "& svg": {
                                        marginRight: 0,
                                    },
                                }}
                            >
                                <GitBranch size="18"/>
                            </Box>
                            <TextField
                                {...params}
                                className="branch-option flex-box-align-center"
                                fullWidth
                                variant="standard"
                            />
                        </Box>
                    )}
                />
            )}
        </Box>
    );
};

export default HeaderSwitch;