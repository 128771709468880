import {MetricType, FirstLastType, FormulaType} from "../components/add-features-component/feature-types";
import {MetricAction} from "../actions/metric-features-reducer";
import {FirstLastAction} from "../actions/first-last-reducer";
import {FormulaAction} from "../actions/formula-features-reducer";


export const metricReducer = (state: MetricType[], action: MetricAction): MetricType[] => {
    switch (action.type) {
        case "CREATE":
            return [...state, action.payload];
        case "DELETE":
            const id = action.payload.id;
            const filteredFeatures: any[] = [];
            for (const metric of state) {
                if (metric?.measures) {
                    const measures = metric?.measures.filter((m) => ![m.name, m.alias].includes(id));
                    if (measures.length) {
                        filteredFeatures.push({...metric, measures});
                    }
                }
            }
            return filteredFeatures;
        case "DELETE_ALL":
            return [];
        default:
            return state;
    }
};


export const firstLastReducer = (state: FirstLastType[], action: FirstLastAction): FirstLastType[] => {
    switch (action.type) {
        case "CREATE":
            return [...state, action.payload];
        case "DELETE":
            const keys = action.payload.id.split("-");
            const fieldName = keys.pop();
            const featureName = keys.join("-");
            const filteredFeatures: any[] = [];
            for (const feature of state) {
                if (feature.id !== featureName) continue;
                if (feature.fields) {
                    const fields = feature.fields.filter((f) => ![f.name, f.alias].includes(fieldName));
                    if (fields.length) {
                        filteredFeatures.push({...feature, fields});
                    }
                }
            }
            return filteredFeatures;
        case "DELETE_ALL":
            return [];
        default:
            return state;
    }
};

export const formulaReducer = (state: FormulaType[], action: FormulaAction): FormulaType[] => {
    switch (action.type) {
        case "CREATE":
            return [...state, action.payload];
        case "DELETE":
            return state.filter(f => f.name !== action.payload.id);
        case "DELETE_ALL":
            return [];
        default:
            return state;
    }
};
