import {FirstLastType} from "../components/add-features-component/feature-types";

export type FirstLastAction =
    | { type: "CREATE"; payload: FirstLastType }
    | { type: "DELETE"; payload: { id: string } }
    | { type: "DELETE_ALL" };

export const createFirstLastFeature = (firstLast: FirstLastType): FirstLastAction => ({
    type: "CREATE",
    payload: {id: `firstLastFeature-${Date.now()}`, ...firstLast}, // using current timestamp as a unique id
});

export const deleteFirstLastFeature = (id: string): FirstLastAction => ({
    type: "DELETE",
    payload: { id },
});

export const deleteAllFirstLastFeatures = (): FirstLastAction => ({
    type: "DELETE_ALL"
});
