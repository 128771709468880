import {useAuthUser} from "@frontegg/react";
import {useGlobalContext} from "context/global-context";
import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {getGitBranches} from "services/entities";
import {getSnowflakeSchemas} from "services/integrations";

const RootComponent = () => {
    const user = useAuthUser();
    const {isUserOnboarded, setUserOnboarded} = useGlobalContext();
    const [loading, setLoading] = useState<boolean>(true);
    const {pathname} = useLocation();
    const navigate = useNavigate() 

    const checkUserOnboarded = async () => {
        if (window.location.pathname.includes("/onboarding")) {
            return;
        }

        let snowflakeData, gitData;

        try {
            snowflakeData = await getSnowflakeSchemas();
        } catch (error) {
            snowflakeData = null;
        }

        try {
            gitData = await getGitBranches();
        } catch (error) {
            gitData = null;
        }

        let isUserOnboarded = Boolean(snowflakeData) && Boolean(gitData)
        setUserOnboarded(isUserOnboarded);
        if (!isUserOnboarded)
            navigate("/onboarding")
        setLoading(false)
    };

    useEffect(() => {
        if (user && !isUserOnboarded && !pathname.includes("onboarding")) 
            checkUserOnboarded();
        else 
            setLoading(false)
    }, [user]);

    return loading ? <></> : <Outlet/>;
};
export default RootComponent;