import React, {ReactNode, useEffect, useState} from "react";
import { FronteggProvider } from "@frontegg/react";
import { ContextOptions } from "@frontegg/types/ContextOptions";
import { getSettings } from "services/system";

const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    
    const [authContextOptions, setAuthContextOptions] = useState<ContextOptions | null>(null)
    
    useEffect(()=>{
        async function getAppSettings(){
            const settings = await getSettings()
            setAuthContextOptions({
                baseUrl: settings.frontegg.base_url,
                clientId: settings.frontegg.client_id
            })
        }

        getAppSettings()
    },[])

    return authContextOptions ? (
        <FronteggProvider
            contextOptions={authContextOptions}
            hostedLoginBox={true}
            authOptions={{
                hostedLoginOptions: {loadUserOnFirstLoad : true},
                keepSessionAlive: true
            }}
        >
            {children}
        </FronteggProvider>
    ) : <></>;
};

export default AuthProvider;