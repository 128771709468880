import {Box, IconButton, Typography} from "@mui/material";
import theme from "theme";
import AliasInput from "../../../../../alias-input.component";
import React from "react";
import {FirstLastFieldType} from "../../../../../feature-types";

interface FirstLastSelectedFieldProps {
    field: FirstLastFieldType,
    onAliasChanged: (fieldName: FirstLastFieldType["name"], alias: FirstLastFieldType["alias"]) => void,
    onFieldDeleted: (fieldName: FirstLastFieldType["name"]) => void,
}

const FirstLastSelectedField: React.FC<FirstLastSelectedFieldProps> = ({field, onAliasChanged, onFieldDeleted}) => {
    return (
        <Box
            key={field.name}
            className="measures-selected"
            sx={{
                position: "relative",
                marginBottom: "12px",
                "&:last-child": {
                    marginBottom: 0,
                },
            }}
        >
            <Box className="flex-box-align-center" sx={{marginBottom: "6px"}}>
                <span
                    className="material-symbols-outlined"
                    style={{
                        fontSize: "24px",
                        color: theme.palette.customColor.purple,
                        marginRight: "6px",
                    }}
                >
                    match_case
                </span>
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                    {field.name}
                </Typography>
            </Box>
            <AliasInput onChange={(alias) => onAliasChanged(field.name, alias)}/>
            <IconButton
                onClick={() => onFieldDeleted(field.name)}
                sx={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    color: theme.palette.customColor.darkGrey,
                    "&:hover": {
                        color: theme.palette.customColor.purple,
                    },
                }}
            >
                <span className="material-symbols-outlined" style={{fontSize: "18px"}}>
                    delete
                </span>
            </IconButton>
        </Box>
    )
};

export default FirstLastSelectedField;