import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import { FeatureRow } from "../business-entity-tabs/business-entity-tabs.component";
import "./business-entity-asset-feature-component.scss";
import BusinessEntityFeatureData, {
    Filter
} from "./business-entity-features-data/business-entity-features-data-component";
import { FeatureType } from "../../../types/features";

interface BusinessEntityAssetFeatureProps {
    assetFeature: FeatureRow;
    formulaFeature?: FeatureRow;
    changeFiltersStyle?: boolean;
}

const BusinessEntityAssetFeature: React.FC<BusinessEntityAssetFeatureProps> = ({
                                                                                   assetFeature,
                                                                                   formulaFeature,
                                                                                   changeFiltersStyle = true,
                                                                               }) => {
    const [isFiltersOpen, setIsFiltersOpen] = useState(true);

    const featureTypeIcons: { [key: string]: string } = {
        metric: "analytics",
        first: "process_chart",
        last: "process_chart",
        formula: "ƒ",
        dim: "linked_services",
    };

    const featureFieldIcons: { [key: string]: string } = {
        type: featureTypeIcons[assetFeature.featureType] || "",
        field: "match_case",
        sortBy: "calendar_today",
        dataAsset: "database",
        filters: "filter_alt",
        measure: "tag",
    };

    const filtersData: Filter[] = (assetFeature.featureFilters || []).map((filter) => ({
        icon: featureFieldIcons.field,
        field: filter.field,
        operator: filter.operator,
        value: Array.isArray(filter.values) ? filter.values.join(", ") : filter.values,
    }));

    const filterCount = (
        <Box className="flex-box-align-center">
            <Typography variant="body1" className="flex-box-center filter-count" sx={{ margin: "0 6px" }}>
                {filtersData.length}
            </Typography>
            <span
                className="material-symbols-outlined"
                style={{
                    fontSize: "16px",
                    color: theme.palette.customColor.dark,
                    transform: isFiltersOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                }}
            >
                keyboard_arrow_down
            </span>
        </Box>
    );

    const featureFields = [
        { name: "Type", icon: featureFieldIcons.type },
        ...(assetFeature.featureType === FeatureType.metric
            ? [{ name: "Measure", icon: featureFieldIcons.measure }]
            : [
                { name: "Field", icon: featureFieldIcons.field },
                { name: "Sort by", icon: featureFieldIcons.sortBy },
            ]),
        { name: "Data asset", icon: featureFieldIcons.dataAsset },
        ...(filtersData.length > 0 ? [{ filterCount, name: "Filters", icon: featureFieldIcons.filters }] : []),
    ];

    return (
        <Box className="flex-box-col-start" sx={{ width: changeFiltersStyle ? "auto" : "100%", gap: "24px" }}>
            {featureFields.map((field, index) => (
                <Box key={index} className={formulaFeature?.featureType === FeatureType.formula ? field.name === "Filters" ? "flex-box-col" : "flex-box-align-center" : "flex-box-align-start"}
                     sx={{ width: "100%" }}>

                    {formulaFeature?.featureType === FeatureType.formula ? (
                        <Box
                            className="flex-box-align-center"
                            sx={{
                                width: field.name === "Filters" ? "138px" : "25px",
                                cursor: field.name === "Filters" ? "pointer" : "default",
                            }}
                            onClick={() => field.name === "Filters" && setIsFiltersOpen(!isFiltersOpen)}
                        >
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: changeFiltersStyle ? "16px" : "18px",
                                color: theme.palette.customColor.purple,
                                marginRight: "10px",
                            }}
                        >
                            {field.icon}
                        </span>
                            <Typography
                                className="flex-box-align-center"
                                variant="subtitle2"
                                sx={{ color: theme.palette.customColor.slateGrey }}
                            >
                                {field.name === "Filters" && "Filters"}{field.name === "Filters" && filterCount}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            className="flex-box-align-center"
                            sx={{
                                width: "138px",
                                cursor: field.name === "Filters" ? "pointer" : "default",
                            }}
                            onClick={() => field.name === "Filters" && setIsFiltersOpen(!isFiltersOpen)}
                        >
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: changeFiltersStyle ? "16px" : "18px",
                                color: theme.palette.customColor.purple,
                                marginRight: "10px",
                            }}
                        >
                            {field.icon}
                        </span>
                            <Typography
                                className="flex-box-align-center"
                                variant="subtitle2"
                                sx={{ color: theme.palette.customColor.slateGrey }}
                            >
                                {field.name}{field.name === "Filters" && filterCount}
                            </Typography>
                        </Box>
                    )}

                    <BusinessEntityFeatureData
                        fieldName={field.name}
                        fieldIcon={field.icon}
                        featureType={assetFeature.featureType}
                        featureField={assetFeature.featureField || ""}
                        featureSortBy={assetFeature.featureSortBy || ""}
                        featureDataAsset={assetFeature.featureDataAsset || ""}
                        featureMeasure={assetFeature.featureMeasure || ""}
                        isFiltersOpen={isFiltersOpen}
                        filtersData={filtersData}
                        changeFiltersStyle={changeFiltersStyle}
                        isFormulaFeature={Boolean(formulaFeature)}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default BusinessEntityAssetFeature;