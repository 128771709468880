import React, {useState, useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';
import './add-edit-business-entities-dim-table-column-second.component.scss';
import { DataAssetResponse } from 'services/data-assets';

interface Props {
    selectedValue: DataAssetResponse | null;
}

const AddEditBusinessEntitiesDimTableColumnSecond: React.FC<Props> = ({selectedValue}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    useEffect(() => {
        setSelectedValues(selectedValue ? selectedValue.keys : []);
    }, [selectedValue]);

    const boxClassName = selectedValue ? '' : 'dim-table-column-sec';

    return (
        <Box className={boxClassName}>
            <Typography
                variant="h5"
                sx={{
                    color: selectedValue ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                    lineHeight: '22px',
                }}
            >
                {selectedValues.length > 0
                    ? selectedValues.join(', ')
                    : "Choose DIM table first"}
            </Typography>
        </Box>
    );
};

export default AddEditBusinessEntitiesDimTableColumnSecond;
