import React, { createContext, useReducer, useContext, ReactNode } from "react";
import {UserAction} from "../actions/user-reducer";
import {userReducer} from "../reducers/user-reducer";
import {UserType} from "types";

interface LoggedInUserProviderProps {
    children: ReactNode;
}

interface UserContextType {
    user: UserType | null;
    dispatchUserAction: React.Dispatch<UserAction>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const LoggedInUserProvider: React.FC<LoggedInUserProviderProps> = ({ children }) => {
    const [user, dispatchUserAction] = useReducer(userReducer, null);

    return (
        <UserContext.Provider value={{user, dispatchUserAction}}>
            {children}
        </UserContext.Provider>
    );
};

const useLoggedInUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useLoggedInUser must be used within a LoggedInUserProvider");
    }
    return context;
};

export { LoggedInUserProvider, useLoggedInUser };
