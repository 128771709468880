import React from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';

interface Props {
    description: string
}

const BusinessEntitiesListDescription: React.FC<Props> = ({description}) => {
    return (
        <Box>
            <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.grey, fontWeight: "400", marginTop: "16px" }}>
                {description}
            </Typography>
        </Box>
    );
};

export default BusinessEntitiesListDescription;
