import React, { BaseSyntheticEvent, FormEvent, useState } from 'react';
import {Box, FormControl, TextareaAutosize, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import './add-edit-business-entities-details.component.scss';
import ButtonComponent from '../../shared/button/button.component';
import { AddEditBusinessEntityStepProps } from '../add-edit-business-entities-step-props';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

export interface AddEditBusinessEntitiesDetailsValues {
    name: string,
    description: string,
    aliases: {value : string}[]
}

interface EntityNameStepProps extends AddEditBusinessEntityStepProps<AddEditBusinessEntitiesDetailsValues>{
}

const AddEditBusinessEntitiesDetails: React.FC<EntityNameStepProps> = ({
    handleFormValues,
    stepFormValues,
    formRef
}) => {
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({defaultValues: stepFormValues || {
        aliases: [{value: ''}],
        name : '',
        description: ''
    }});
    const {fields: aliases, append: addAliasField, remove: removeAliasField} = useFieldArray({control, name:"aliases"})
    
    const addAlias = () => {
        addAliasField({value: ''})
    };

    const removeAlias = (index: number) => {
        return () => removeAliasField(index)
    };

    const onSubmit = (data: any) => {
        const values = {
            name: data.name,
            description: data.description,
            aliases: data.aliases
        }
        handleFormValues(values);
    };

    return (
        <Box component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)} sx={{maxWidth: '620px', width: '100%', margin: '50px 0'}}>
            <FormControl fullWidth>
                <Box sx={{marginBottom: '32px'}}>
                    <Typography
                        variant="h5"
                        sx={{color: theme.palette.customColor.darkGrey}}
                        lineHeight="22px"
                        mb="10px"
                    >
                        Name
                    </Typography>
                    <Controller
                        name={"name"}
                        control={control}
                        render={() => (
                            <TextField
                                {...register("name", {required: true})}
                                fullWidth 
                                id="name" 
                                placeholder="Enter entity name" 
                                type="text"
                                error={!!errors["name"]}
                                helperText={errors["name"] ? `Name is required` : ""}/>
                        )} 
                    />
                </Box>
            </FormControl>
            <FormControl fullWidth>
                <Box>
                    <Typography
                        variant="h5"
                        sx={{color: theme.palette.customColor.darkGrey}}
                        lineHeight="22px"
                        mb="10px"
                    >
                        Description
                    </Typography>
                    <Controller
                        name={"description"}
                        control={control}
                        render={() => (
                            <TextareaAutosize
                                {...register("description", {required: false})}
                                id="description"
                                className="text-area-connect-git text-area-size"
                                minRows={7}
                                placeholder="Your entity description..."
                            />
                        )} 
                    />
                </Box>
            </FormControl>
            <FormControl fullWidth>
                <Box sx={{marginTop: '32px'}}>
                    <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                        Also known as
                    </Typography>
                    {aliases.map((alias, index) => (
                        <Box sx={{marginTop: '10px'}} key={alias.id}>
                            <Box className="flex-box-align-center" sx={{marginBottom: '10px'}}>
                                <TextField
                                    {...register(`aliases.${index}.value`)} 
                                    fullWidth
                                    placeholder="Enter entity name"
                                    type="text"
                                    defaultValue={alias}
                                    sx={{marginRight: '16px'}}
                                />
                                <span
                                    onClick={removeAlias(index)}
                                    style={{cursor:"pointer"}}
                                    className="material-symbols-outlined">delete</span>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </FormControl>
            <ButtonComponent
                variant="textPurple"
                label="+ Add new"
                onClick={addAlias}
                sx={{paddingLeft: 0}}
            />
        </Box>
    );
};

export default AddEditBusinessEntitiesDetails;
